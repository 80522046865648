import React from "react";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";

const Plant = (props) => {

    const {t} = useTranslation();

    return (

        <div className='pb-3'>
            <div className='view-entity-container'>
                <div>
                    <div>{t("plants.form.field1.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.name}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.field4.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.systemArea}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.field2.name")}</div>
                    <div>
                        <div className='form-control'>{props.categoryName}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.field3.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.species?.common_name}</div>
                    </div>
                </div>

                <div className='view-entity-container'>
                    <div className="mt-5 col-12">
                        <hr/>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field1")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.scientific_name}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field2")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.min_vegetation_temp}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field3")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.max_vegetation_temp}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field4")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.ec_max}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field5")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.ph_min}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field6")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.ph_max}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field7")}</div>
                        <div>
                            <div className="d-flex">
                                <Form.Check
                                    type="radio"
                                    value="yes"
                                    id="resistance_to_frost-yes"
                                    name="resistance_to_frost"
                                    label={t('plants.form.species.field7.value1')}
                                    checked={props?.formState?.species?.resistance_to_frost === true}
                                    className="me-4"
                                    readOnly
                                />
                                <Form.Check
                                    type="radio"
                                    value="no"
                                    id="resistance_to_frost-no"
                                    name="resistance_to_frost"
                                    label={t('plants.form.species.field7.value2')}
                                    checked={props?.formState?.species?.resistance_to_frost === false}
                                    className="me-4"
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field8")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.co2}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field9")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.pm10}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field10")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.pm2_5}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field11")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.no2}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field12")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.so2}</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className="mb-2">{t("plants.form.species.field13")}</div>
                        <div>
                            <div className='form-control'>{props?.formState?.species?.o3}</div>
                        </div>
                    </div>

                    <div className="mt-5 col-12">
                        <hr/>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.notes.name")}</div>
                    <div>
                        <div className='form-control' style={{minHeight: '70px'}}>{props.formState.notes}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Plant;