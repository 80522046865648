export const GET_PLANT_CATEGORY_LIST_REQUEST = "GET_PLANT_CATEGORY_LIST_REQUEST";
export const GET_PLANT_CATEGORY_LIST_SUCCEED = "GET_PLANT_CATEGORY_LIST_SUCCEED";
export const GET_PLANT_CATEGORY_LIST_FAILED = "GET_PLANT_CATEGORY_LIST_FAILED";

export const GET_PLANT_CATEGORY_DATA_REQUEST = "GET_PLANT_CATEGORY_DATA_REQUEST";
export const GET_PLANT_CATEGORY_DATA_SUCCEED = "GET_PLANT_CATEGORY_DATA_SUCCEED";
export const GET_PLANT_CATEGORY_DATA_FAILED = "GET_PLANT_CATEGORY_DATA_FAILED";

export const CREATE_PLANT_CATEGORY_REQUEST = "CREATE_PLANT_CATEGORY_REQUEST";
export const CREATE_PLANT_CATEGORY_SUCCEED = "CREATE_PLANT_CATEGORY_SUCCEED";
export const CREATE_PLANT_CATEGORY_FAILED = "CREATE_PLANT_CATEGORY_FAILED";

export const UPDATE_PLANT_CATEGORY_REQUEST = "UPDATE_PLANT_CATEGORY_REQUEST";
export const UPDATE_PLANT_CATEGORY_SUCCEED = "UPDATE_PLANT_CATEGORY_SUCCEED";
export const UPDATE_PLANT_CATEGORY_FAILED = "UPDATE_PLANT_CATEGORY_FAILED";

export const GET_PLANT_CATEGORY_SPECIES_LIST_REQUEST = "GET_PLANT_CATEGORY_SPECIES_LIST_REQUEST";
export const GET_PLANT_CATEGORY_SPECIES_LIST_SUCCEED = "GET_PLANT_CATEGORY_SPECIES_LIST_SUCCEED";
export const GET_PLANT_CATEGORY_SPECIES_LIST_FAILED = "GET_PLANT_CATEGORY_SPECIES_LIST_FAILED";

export const CREATE_PLANT_CATEGORY_SPECIES_REQUEST = "CREATE_PLANT_CATEGORY_SPECIES_REQUEST";
export const CREATE_PLANT_CATEGORY_SPECIES_SUCCEED = "CREATE_PLANT_CATEGORY_SPECIES_SUCCEED";
export const CREATE_PLANT_CATEGORY_SPECIES_FAILED = "CREATE_PLANT_CATEGORY_SPECIES_FAILED";

export const UPDATE_PLANT_CATEGORY_SPECIES_REQUEST = "UPDATE_PLANT_CATEGORY_SPECIES_REQUEST";
export const UPDATE_PLANT_CATEGORY_SPECIES_SUCCEED = "UPDATE_PLANT_CATEGORY_SPECIES_SUCCEED";
export const UPDATE_PLANT_CATEGORY_SPECIES_FAILED = "UPDATE_PLANT_CATEGORY_SPECIES_FAILED";
