import React, {useEffect, useState} from "react";
import {Modal, ModalBody, Button, ModalHeader, Input, Row} from "reactstrap";
import {CloseButton, ModalFooter} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useParams, Link} from "react-router-dom";

import {ReactComponent as Seedling} from '../../assets/icons/seedling.svg'
import PlantSpeciesForm from "../forms/PlantSpeciesForm";

function PlantSpeciesModal({modalShow, setModalShow, formState, setFormState, onFormSubmit, isView, isEdit}) {

    const {t} = useTranslation();
    const params = useParams();

    const toggleModal = () => {
        setModalShow(!modalShow)
    }

    return (
        <Modal isOpen={modalShow} size="lg" className="driver-rule-modal-container">
            <ModalHeader className='driver-rule-modal pe-5 pt-4'>
                {isView ?
                    <div>{formState.common_name}</div>
                    :
                    isEdit ?
                        <div>{t("main.edit")} {t("plantSpecies.add")}</div>
                        :
                        <div>{t("main.add")} {t("plantSpecies.add")}</div>
                }
                <CloseButton onClick={toggleModal} variant="white"/>
                <div style={{
                    position: 'absolute',
                    bottom: '-32px',
                    left: '20px',
                    backgroundColor: '#EFF2F7',
                    padding: '13px',
                    borderRadius: '50%',
                    height: '62px',
                    width: '62px',
                    zIndex: 1000
                }}>
                    <Seedling style={{width: "2rem", marginTop: 10}} />
                </div>
            </ModalHeader>

            <form onSubmit={onFormSubmit}>
                <ModalBody className="mt-5 overflow-scroll" style={{maxHeight: "60vh"}}>
                    <PlantSpeciesForm formState={formState} setFormState={setFormState} isView={isView} />
                </ModalBody>

                <ModalFooter>
                    <div className="text-end form-btn-grp pe-4 mt-1 mb-1">
                        {!isView ? <Button color="primary" className="me-2">{t("main.save")}</Button> :""}
                        <Button color="secondary" onClick={toggleModal}>{t(isView ? "main.back" : "main.cancel")}</Button>
                    </div>
                </ModalFooter>
            </form>


        </Modal>);
}

export default PlantSpeciesModal