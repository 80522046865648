import React, {useState} from "react";
import {Button, Form, Row} from "react-bootstrap";
import {Col, Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";


const PlantSpeciesForm=(props)=>{

    const { t } = useTranslation();
    const params = useParams();

    const onChangeHandler=(e)=>{
        const { name, value } = e.target;
        props.setFormState({
            ...props.formState,
            [name]: value
        })
    }


    return (
        <div>
            <Row>
                <div className="col-md-6">
                    <div>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='common_name'>{t('plantSpecies.form.field1.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='text'
                                name='common_name'
                                id='common_name'
                                className='form-control'
                                value={props.formState.common_name}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field1.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='scientific_name'>{t('plantSpecies.form.field2.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='text'
                                name='scientific_name'
                                id='scientific_name'
                                className='form-control'
                                value={props.formState.scientific_name}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field2.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='min_vegetation_temp'>{t('plantSpecies.form.field3.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='min_vegetation_temp'
                                id='min_vegetation_temp'
                                className='form-control'
                                value={props.formState.min_vegetation_temp}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field3.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='max_vegetation_temp'>{t('plantSpecies.form.field4.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='max_vegetation_temp'
                                id='max_vegetation_temp'
                                className='form-control'
                                value={props.formState.max_vegetation_temp}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field4.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='ec_max'>{t('plantSpecies.form.field5.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='ec_max'
                                id='ec_max'
                                className='form-control'
                                value={props.formState.ec_max}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field5.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='ph_min'>{t('plantSpecies.form.field6.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='ph_min'
                                id='ph_min'
                                className='form-control'
                                value={props.formState.ph_min}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field6.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='ph_max'>{t('plantSpecies.form.field7.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='ph_max'
                                id='ph_max'
                                className='form-control'
                                value={props.formState.ph_max}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field7.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className="mt-3">
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='resistance_to_frost'>{t('plantSpecies.form.field14.name')}</label>
                        </div>
                        <div className="d-flex">
                            <Form.Check
                                type="radio"
                                value="yes"
                                id="resistance_to_frost-yes"
                                name="resistance_to_frost"
                                label={t('plantSpecies.form.field14.value1')}
                                onChange={onChangeHandler}
                                checked={props.formState.resistance_to_frost === 'yes'}
                                className="me-4"
                                disabled={props.isView}
                            />
                            <Form.Check
                                type="radio"
                                value="no"
                                id="resistance_to_frost-no"
                                name="resistance_to_frost"
                                label={t('plantSpecies.form.field14.value2')}
                                onChange={onChangeHandler}
                                checked={props.formState.resistance_to_frost === 'no'}
                                disabled={props.isView}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div>
                        <label className='mb-2 p-0'>{t('plantSpecies.form.section1')}</label>
                    </div>
                    <div>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='co2'>{t('plantSpecies.form.field8.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='co2'
                                id='co2'
                                className='form-control'
                                value={props.formState.co2}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field8.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div style={{marginTop: "30px"}}>
                        <label className='mb-2 p-0'>{t('plantSpecies.form.section2')}</label>
                    </div>
                    <div>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='pm10'>{t('plantSpecies.form.field9.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='pm10'
                                id='pm10'
                                className='form-control'
                                value={props.formState.pm10}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field9.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='pm2_5'>{t('plantSpecies.form.field10.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='pm2_5'
                                id='pm2_5'
                                className='form-control'
                                value={props.formState.pm2_5}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field10.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='no2'>{t('plantSpecies.form.field11.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='no2'
                                id='no2'
                                className='form-control'
                                value={props.formState.no2}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field11.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='so2'>{t('plantSpecies.form.field12.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='so2'
                                id='so2'
                                className='form-control'
                                value={props.formState.so2}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field12.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div>
                            <label className='mb-2 p-0'
                                   htmlFor='o3'>{t('plantSpecies.form.field13.name')} *</label>
                        </div>
                        <div>
                            <Input
                                type='number'
                                inputMode="numeric"
                                name='o3'
                                id='o3'
                                className='form-control'
                                value={props.formState.o3}
                                onChange={onChangeHandler}
                                placeholder={t('plantSpecies.form.field13.placeholder')}
                                required
                                disabled={props.isView}
                            />
                        </div>
                    </div>
                </div>
            </Row>

        </div>
    )
}


export default PlantSpeciesForm;