import React from "react";
import {Button, Card, CardBody, CardTitle} from "reactstrap";
import moment from 'moment/min/moment-with-locales'
import {useTranslation} from "react-i18next";

import {config} from "../../../config";

const IndexCardNormal = (props) => {

    const {t} = useTranslation();

    return (
        <Card className='index-card'>
            <CardBody>
                <CardTitle style={{textAlign: 'left', minHeight: 44}}>{props.datas?.name}</CardTitle>
                <div className='font-size-11 fw-light position-absolute' style={{bottom: 10, right: 10}}>{t("dashboard.health.lastUpdate")}: {props.datas?.state?.time ? moment(props.datas?.state?.time).locale(config.LANGUAGE).fromNow() : '-'}</div>

                {(props.datas?.type?.includes("wellbeing-index-operators") || props.datas?.type?.includes("wellbeing-index-users") || props.datas?.type?.includes("garden-heat-index")) ?
                    <div className='mt-5 mb-3'>
                        <div className="text-center" style={props.datas?.state?.value ? {fontSize: 60, color: "#ADB5BD", height: 90} : {fontSize: 30, color: "#ADB5BD", height: 90}}>{props.datas?.state?.value ?? "-"}</div>
                        {props.datas?.type?.includes("wellbeing-index-") || props.datas?.type?.includes("garden-heat-index") ?
                            <div className="text-center text-uppercase font-size-17" style={{color: "#ADB5BD"}}>{t(`dashboard.index.label.${props.datas?.type}.uom`)}</div>
                            :""}
                        <div className="mt-5" style={{minHeight: 40}}>
                            {props.datas?.type?.includes("wellbeing-index-") ?
                                <div className="font-size-13 text-center" style={{color: "#707070"}}>{props.datas?.state?.attributes?.labelId ? t(`dashboard.index.label.${props.datas?.type}.${props.datas?.state?.attributes?.labelId}`) : 'N/D'}</div>
                                :""}
                            {props.datas?.type?.includes("garden-heat-index") ?
                                <div className="font-size-13 text-center" style={{color: "#707070"}}>{t("dashboard.index.label.garden-heat-index")} = {props.datas?.state?.attributes?.sgl_ta ? `${props.datas?.state?.attributes?.sgl_ta}` : 'N/D'}</div>
                                :""}
                        </div>

                        {props.datas?.type?.includes("wellbeing-index-operators") ?
                            <div className="mt-5 text-center">
                                {(!props.datas?.state) ?
                                    <Button color='light' className='px-4' style={{borderRadius: 50}}>-</Button>
                                    :
                                    (props.datas?.state?.attributes?.labelId === "low" || props.datas?.state?.attributes?.labelId === "very-low" || props.datas?.state?.attributes?.labelId === "none") ?
                                        <Button color='success' className='px-4' style={{borderRadius: 50}}>{t('main.ok')}</Button>
                                        :
                                        props.datas?.state?.attributes?.labelId === "moderate" ?
                                            <Button color='warning' className='px-4' style={{borderRadius: 50}}>{t('main.warning')}</Button>
                                            :
                                            <Button color='danger' className='px-4' style={{borderRadius: 50}}>{t('main.danger')}</Button>
                                }
                            </div>
                            : props.datas?.type?.includes("wellbeing-index-users") ?
                                <div className="mt-5 text-center">
                                    {(!props.datas?.state) ?
                                        <Button color='light' className='px-4' style={{borderRadius: 50}}>-</Button>
                                        :
                                        props.datas?.state?.attributes?.labelId === "wellbeing" ?
                                            <Button color='success' className='px-4' style={{borderRadius: 50}}>{t('main.ok')}</Button>
                                            :
                                            (props.datas?.state?.attributes?.labelId === "light-discomfort" || props.datas?.state?.attributes?.labelId === "growing-discomfort" || props.datas?.state?.attributes?.labelId === "discomfort") ?
                                                <Button color='warning' className='px-4' style={{borderRadius: 50}}>{t('main.warning')}</Button>
                                                :
                                                <Button color='danger' className='px-4' style={{borderRadius: 50}}>{t('main.danger')}</Button>
                                    }
                                </div>
                                :
                                <div className="mt-5 text-center">
                                    {(!props.datas?.state) ?
                                        <Button color='light' className='px-4' style={{borderRadius: 50}}>-</Button>
                                        :
                                        props.datas?.state?.value > 2 ?
                                            <Button color='warning' className='px-4' style={{borderRadius: 50}}>{t('main.warning')}</Button>
                                            :
                                                <Button color='success' className='px-4' style={{borderRadius: 50}}>{t('main.ok')}</Button>
                                    }
                                </div>
                        }

                    </div>
                :""}
            </CardBody>
        </Card>
    )
}

export default IndexCardNormal;