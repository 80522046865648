import {irrigationManagementActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getAvailableDeviceTypesOptions = (systemId, isSwitch) => async dispatch => {
    dispatch({type: isSwitch ? irrigationManagementActionTypes.GET_AVAILABLE_SWITCH_DEVICES_LIST_REQUEST : irrigationManagementActionTypes.GET_AVAILABLE_MOISTURE_DEVICES_LIST_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/devices/availableDevices/?system=${systemId}&device_type=${isSwitch ? 'switch' : 'soil-moisture-sensor'}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: isSwitch ? irrigationManagementActionTypes.GET_AVAILABLE_SWITCH_DEVICES_LIST_SUCCEED : irrigationManagementActionTypes.GET_AVAILABLE_MOISTURE_DEVICES_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: isSwitch ? irrigationManagementActionTypes.GET_AVAILABLE_SWITCH_DEVICES_LIST_FAILED : irrigationManagementActionTypes.GET_AVAILABLE_MOISTURE_DEVICES_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getAvailableSystemUserOptions = (systemId) => async dispatch => {
    dispatch({type: irrigationManagementActionTypes.GET_SYSTEM_USER_LIST_REQUEST})

    try {
        const res = await request({
            url: `core/ui/systems/availableUsers/?system=${systemId}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: irrigationManagementActionTypes.GET_SYSTEM_USER_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: irrigationManagementActionTypes.GET_SYSTEM_USER_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getIrrigationManagementData = (systemId) => async dispatch => {
    dispatch({type: irrigationManagementActionTypes.GET_IRRIGATION_MANAGEMENT_DATA_REQUEST})

    try {
        const res = await request({
            url: `rulesystem/ui/irrigation/?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: irrigationManagementActionTypes.GET_IRRIGATION_MANAGEMENT_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: irrigationManagementActionTypes.GET_IRRIGATION_MANAGEMENT_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}


export const updateIrrigationManagementData = (Data, sectorData, systemId) => async dispatch => {
    dispatch({type: irrigationManagementActionTypes.UPDATE_IRRIGATION_MANAGEMENT_DATA_REQUEST});

    let data = new URLSearchParams();

    data.append("active_irrigation_management", Data.active_irrigation_management);
    data.append("inibition_device_slug_id", Data.inibition_device_slug_id);

    if(Data?.notification_user_emails?.length) {
        for(let i=0; i<Data?.notification_user_emails?.length; i++) {
            data.append(`notification_user_emails[${i}]`, Data?.notification_user_emails[i]?.value);
        }
    }

    const weekArr = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    if(sectorData?.length) {
        for(let i=0; i<sectorData?.length; i++) {
            data.append(`sectors[${i}][name]`, sectorData[i]?.name);
            data.append(`sectors[${i}][minv_sm]`, sectorData[i]?.minv_sm);
            data.append(`sectors[${i}][maxv_sm]`, sectorData[i]?.maxv_sm);
            data.append(`sectors[${i}][valve_device_slug_id]`, sectorData[i]?.valve_device_slug_id);
            data.append(`sectors[${i}][irrigation_threshold_value]`, sectorData[i]?.irrigation_threshold_value);
            data.append(`sectors[${i}][minutes_irrigation_time]`, sectorData[i]?.minutes_irrigation_time);
            data.append(`sectors[${i}][minutes_stop_time]`, sectorData[i]?.minutes_stop_time);
            data.append(`sectors[${i}][max_irrigation_cycles]`, sectorData[i]?.max_irrigation_cycles);

            if(sectorData[i]?.sm_devices?.length) {
                for(let j=0; j<sectorData[i]?.sm_devices?.length; j++) {
                    data.append(`sectors[${i}][sm_devices_slug_ids][${j}]`, sectorData[i]?.sm_devices[j]?.value ? sectorData[i]?.sm_devices[j]?.value : sectorData[i]?.sm_devices[j]?.slug_id);
                }
            }

            for(let j=0; j<weekArr.length; j++) {
                if(sectorData[i]?.stop_irrigations_hour_ranges && sectorData[i]?.stop_irrigations_hour_ranges[weekArr[j]] && sectorData[i]?.stop_irrigations_hour_ranges[weekArr[j]]?.length){
                    const hourlyRangesArr = sectorData[i]?.stop_irrigations_hour_ranges[weekArr[j]];

                    for(let k=0; k<hourlyRangesArr.length; k++) {
                        data.append(`sectors[${i}][stop_irrigations_hour_ranges][${weekArr[j]}][${k}]`, hourlyRangesArr[k]);
                    }
                }
            }

        }
    }

    try {
        const res = await request({
            url: `rulesystem/ui/irrigation/?system=${systemId}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: irrigationManagementActionTypes.UPDATE_IRRIGATION_MANAGEMENT_DATA_SUCCEED,
            payload: res
        });
        return true;

    } catch (err) {
        dispatch({
            type: irrigationManagementActionTypes.UPDATE_IRRIGATION_MANAGEMENT_DATA_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}
