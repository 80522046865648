import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CardBody, Card, Row, Col} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import PlantSpeciesList from "./PlantSpeciesList";
import PlantCategoryForm from "../../components/forms/PlantCategoryForm";

import {
    addPlantCategory,
    getSinglePlantCategoryData,
    updatePlantCategory
} from "../../redux/actions/plantCategoryActions";


const PlantCategoryAddEditView = ({isEdit, isView}) => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [formState, setFormState] = useState({
        name: '',
        note: '',
    })

    const plantCategoryData = useSelector((el)=> el.plantCategoryData.plantCategoryData)

    useEffect(() => {
        if(isEdit || isView){
            dispatch(getSinglePlantCategoryData(params.plantCategoryId))
        }
    }, [])

    useEffect(() => {
        if (plantCategoryData && (isEdit || isView)) {
            setFormState({
                ...formState,
                _id: plantCategoryData._id,
                name: plantCategoryData.name,
                note: plantCategoryData.notes,
            })
        }
    }, [plantCategoryData])

    const onSaveClick = (e) => {
        e.preventDefault();

        let data = {};
        data.name = formState.name;
        data.note = formState.note;

        if(formState._id){
            data._id = formState._id;
            dispatch(updatePlantCategory(data)).then((res)=>{
                if(res){
                    history.push(`/plant-categories`)
                }
            })
        } else {
            dispatch(addPlantCategory(data)).then((res)=>{
                if(res){
                    history.push(`/plant-categories`)
                }
            })
        }
    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={isEdit ? t('plantCategories.editPlantCategory') : isView ? plantCategoryData?.name : t('plantCategories.addPlantCategory')}
                    breadcrumbItems={[
                        {path: `plant-categories`, name: t('plantCategories.breadcrumb1')},
                        {
                            path: isEdit ? `plant-categories/edit/${params.plantCategoryId}` : isView ? `plant-categories/view/${params.plantCategoryId}` : "plant-categories/add",
                            name: t(isEdit ? 'plantCategories.breadcrumbEdit' : isView ? plantCategoryData?.name : 'plantCategories.breadcrumbAdd')
                        },
                    ]}
                />

                <Card>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <PlantCategoryForm
                                    onSaveClick={onSaveClick}
                                    formState={formState}
                                    setFormState={setFormState}
                                    isView={isView}
                                />
                            </Col>
                            <Col md={6} className="ps-md-4 pe-md-3">
                                <PlantSpeciesList isView={isView} />
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </div>
        </Layout>
    )
}

export default PlantCategoryAddEditView;