import React from "react";
import {Button, Card, CardBody, CardTitle, Row} from "reactstrap";
import moment from 'moment/min/moment-with-locales'
import {useTranslation} from "react-i18next";

import {config} from "../../../config";
import ReactApexChart from "react-apexcharts";

const IndexCardSmall = (props) => {

    const {t} = useTranslation();

    const options = {
        options: {
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                        name: {
                            fontSize: "13px",
                            color: void 0,
                            offsetY: 60,
                        },
                        value: {
                            offsetY: 22,
                            fontSize: "16px",
                            color: void 0,
                            formatter: function (e) {
                                return e + "%"
                            },
                        },
                    },
                },
            },
            colors: [props.datas?.state?.value && props.datas?.state?.value > 70 ? "#F46A6A" : props.datas?.state?.value > 50 ? "#F1B44C" : "#34c38f"],

            stroke: {
                dashArray: 4,
            },
            labels: [t("main.riskLevel")],
        },
        series: [props.datas?.state?.value ? props.datas?.state?.value : 0],
    };

    return (
        <Card className='index-card-small'>
            <CardBody>
                <CardTitle style={{textAlign: 'left'}}>{props.datas?.name}</CardTitle>
                <div className='font-size-11 fw-light position-absolute' style={{bottom: 10, right: 10}}>{t("main.lastUpdate")}: {props.datas?.state?.time ? moment(props.datas?.state?.time).locale(config.LANGUAGE).fromNow() : '-'}</div>

                {props.datas?.type?.includes("degreedays-sum") ?
                    <div className="mt-5 mb-5">
                        {props.datas?.state?.attributes?.currentGen > 0 ?
                            <div className="d-flex justify-content-between align-items-center">
                                <Button color='danger' className='px-5' style={{borderRadius: 50}}>{t('main.danger')}</Button>
                                <div style={{color: "#707070"}}>{t("main.generation")} {props.datas?.state?.attributes?.currentGen || ""} <span style={{color: "#707070"}} className="fw-medium">({props.datas?.state?.value})</span></div>
                            </div>
                            :
                            <div className="d-flex justify-content-between  align-items-center">
                                <Button color='success' className='px-5' style={{borderRadius: 50}}>{t('main.ok')}</Button>
                                <div style={{color: "#707070"}}>{t("main.noGeneration")} <span style={{color: "#707070"}} className="fw-medium">{props.datas?.state?.value ? `(${props.datas?.state?.value})` : ''}</span></div>
                            </div>
                        }
                    </div>
                :
                    <div className="d-flex justify-content-between align-items-center mt-0">
                        <div>
                            <Row>
                                <div className='col-6'>
                                    <div className="me-4 rounded-pill pill-danger">{"> 70"}</div>
                                </div>
                                <div className='col-6 text-nowrap text-start ps-4'>
                                    {t("main.high")}
                                </div>
                            </Row>

                            <Row className='mt-2'>
                                <div className='col-6'>
                                    <div className="me-4 rounded-pill pill-warning">50 - 70</div>
                                </div>
                                <div className='col-6 text-nowrap text-start ps-4'>
                                    {t("main.moderate")}
                                </div>
                            </Row>

                            <Row className='mt-2'>
                                <div className='col-6'>
                                    <div className="me-4 rounded-pill pill-success">0 - 50</div>
                                </div>
                                <div className='col-6 text-nowrap text-start ps-4'>
                                    {t("main.low")}
                                </div>
                            </Row>
                        </div>

                        <div style={{marginTop: -30, marginRight: -20}}>
                            <ReactApexChart
                                options={options.options}
                                series={options.series}
                                type="radialBar"
                                height="160"
                                className="apex-charts"
                                width={160}
                            />
                        </div>
                    </div>
                }
            </CardBody>
        </Card>
    )
}

export default IndexCardSmall;