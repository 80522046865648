import {gardenInfoActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getGardenTypesOptions = () => async dispatch => {
    dispatch({type: gardenInfoActionTypes.GET_GARDEN_TYPES_REQUEST})

    try {
        const res = await request({
            url: `core/ui/gardenInfo/gardenTypes`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: gardenInfoActionTypes.GET_GARDEN_TYPES_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: gardenInfoActionTypes.GET_GARDEN_TYPES_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getGardenInfoSectionData = (systemId, sectionId) => async dispatch => {
    dispatch({type: gardenInfoActionTypes.GET_GARDEN_INFO_SECTION_DATA_REQUEST})

    let type;

    if(sectionId === 1) type = "historicGarden";
    else if(sectionId === 2) type = "localization";
    else if(sectionId === 4) type = "contactReference";
    else if(sectionId === 5) type = "soilCharacteristics";
    else if(sectionId === 6) type = "irrigationWaterCharacteristics";
    else if(sectionId === 7) type = "images";

    try {
        const res = await request({
            url: `core/ui/gardenInfo/${type}?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        const resData = {
            section: 'section' + sectionId,
            data: res
        }

        dispatch({
            type: gardenInfoActionTypes.GET_GARDEN_INFO_SECTION_DATA_SUCCEED,
            payload: resData
        });
    } catch (err) {
        dispatch({
            type: gardenInfoActionTypes.GET_GARDEN_INFO_SECTION_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}


export const updateGardenInfoSectionData = (Data, systemId, sectionId) => async dispatch => {
    dispatch({type: gardenInfoActionTypes.UPDATE_GARDEN_INFO_SECTION_REQUEST});

    let type;
    let data;

    if(sectionId !== 7) {
        data = new URLSearchParams();
        data.append("system", systemId);
    } else {
        data = new FormData();
    }

    if(sectionId === 1) {
        type = "historicGarden";

        data.append("historic_garden[acquisition]", Data.acquisition)
        data.append("historic_garden[garden_type]", Data.garden_type)
        data.append("historic_garden[notes]", Data.notes)
    } else if(sectionId === 2) {
        type = "localization";

        data.append("localization[address]", Data.address)
    } else if(sectionId === 4) {
        type = "contactReference";

        data.append("contact_reference[phone_number]", Data.phone_number)
        data.append("contact_reference[email]", Data.email)
        data.append("contact_reference[company_address]", Data.company_address)
        data.append("contact_reference[notes]", Data.notes)
        data.append("contact_reference[property_type]", Data.property_type)
    } else if(sectionId === 5) {
        type = "soilCharacteristics";

        data.append("soil_characteristics[phisical_characteristics]", Data.phisical_characteristics)
        data.append("soil_characteristics[ph]", Data.ph)
        data.append("soil_characteristics[salinity]", Data.salinity)
        data.append("soil_characteristics[chemical_analysis]", Data.chemical_analysis)
        data.append("soil_characteristics[notes]", Data.notes)

    } else if(sectionId === 6) {
        type = "irrigationWaterCharacteristics";

        data.append("irrigation_water_characteristics[source_of_supply]", Data.source_of_supply)
        data.append("irrigation_water_characteristics[ec]", Data.ec)
        data.append("irrigation_water_characteristics[ph]", Data.ph)
        data.append("irrigation_water_characteristics[chemical_analysis]", Data.chemical_analysis)
        data.append("irrigation_water_characteristics[notes]", Data.notes)
    }
    else if(sectionId === 7) {
        type = "images";

        for(let i=0; i<Data.length; i++) {
            if(Data[i]?.file) {
                data.append("images", Data[i]?.file);
            }
            data.append(`order[${i}]`, Data[i]._id ? Data[i]._id : Data[i].file.name);
        }

        if(Data.length === 0) {
            data.append("order[]", "");
        }
    }

    try {
        const res = await request({
            url: `core/ui/gardenInfo/${type}?system=${systemId}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        const resData = {
            section: 'section' + sectionId,
            data: res
        }

        dispatch({
            type: gardenInfoActionTypes.UPDATE_GARDEN_INFO_SECTION_SUCCEED,
            payload: resData
        });
        return true;

    } catch (err) {
        dispatch({
            type: gardenInfoActionTypes.UPDATE_GARDEN_INFO_SECTION_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}
