import React, {useState} from "react";
import {Row} from "react-bootstrap";
import {Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import axios from 'axios'

import Map_with_Polygon from "../common/Map_with_Polygon";
import { config } from "../../config";

const key = config.GOOGLE_API_KEY;

const SystemPLT = (props) => {

    const {t} = useTranslation();

    const [placesState, setPlacesState] = useState({
        latitude: '',
        longitude: ''
    })

    const onChangeHandler=(e, isPlace)=>{
        const { name, value } = e.target;
        if(isPlace){
            setPlacesState({
                ...placesState,
                [name]: value
            })
        } else {
            props.setFormState({
                ...props.formState,
                [name]: value
            })
        }
    }

    const addPath = () => {
        let oldPaths = JSON.parse(JSON.stringify(props.formState.paths));
        oldPaths.push({
            lat: Number(placesState.latitude),
            lng: Number(placesState.longitude),
        })

        props.setFormState({
            ...props.formState,
            paths: oldPaths
        })
        setPlacesState({
            latitude: '',
            longitude: ''
        })
    }

    const searchLocation = () => {
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${props.formState.geoLocation.replaceAll(' ', '+')}&key=${key}`)
            .then((res) => {
                let mapCenter = res?.data?.results[0]?.geometry?.location
                let location = res?.data?.results[0]?.formatted_address
                props.setFormState({
                    ...props.formState,
                    'geoLocation': location || "",
                    'mapCenter': mapCenter
                })
            })
            .catch((e) => {
                console.log(e)
            })

    }

    return (

        <div className='pb-3'>
            <Row>
                <div className='fw-bold mb-3'>{t('systems.form2.card.topic')}</div>
            </Row>

            <Row>
                <div className='mt-3'>
                    <div><label className='mb-2 p-0' htmlFor='visibility'>{t('systems.form2.field1.name')} *</label>
                    </div>
                    <div>
                        <Input
                            type='select'
                            name='visibility'
                            id='visibility'
                            className='form-control form-select'
                            value={props.formState.visibility}
                            onChange={onChangeHandler}
                            required
                        >
                            <option hidden value=''>{t('systems.form2.field1.placeholder')}</option>
                            <option value='public'>{t('systems.form2.field1.option1')}</option>
                            <option value='reserved'>{t('systems.form2.field1.option2')}</option>
                        </Input>
                    </div>
                </div>
            </Row>

            <Row>
                <div className='mt-3'>
                    <div><label className='mb-2 p-0' htmlFor='irrigation_enabled'>{t('systems.form2.field4.name')} *</label>
                    </div>
                    <div>
                        <Input
                            type='select'
                            name='irrigation_enabled'
                            id='irrigation_enabled'
                            className='form-control form-select'
                            value={props.formState.irrigation_enabled}
                            onChange={onChangeHandler}
                            required
                        >
                            <option hidden value=''>{t('systems.form2.field4.placeholder')}</option>
                            <option value='enabled'>{t('systems.form2.field4.option1')}</option>
                            <option value='disabled'>{t('systems.form2.field4.option2')}</option>
                        </Input>
                    </div>
                </div>
            </Row>

            <Row className='mt-3'>
                <div className='mt-3 col-md-6'>
                    <form onSubmit={searchLocation} onKeyPress={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            searchLocation()
                        }
                    }}>
                        <div>
                            <label className='mb-1 p-0' htmlFor='geoLocation'>{t('systems.form2.field2.name')}</label>
                        </div>
                        <div className='fw-light text-muted mb-2 font-size-11'>{t('systems.form2.field2.info')}.</div>
                        <div style={{position: 'relative'}}>
                            <Input
                                type='text'
                                name='geoLocation'
                                id='geoLocation'
                                className='form-control'
                                value={props.formState.geoLocation}
                                onChange={onChangeHandler}
                            />
                            <div><i onClick={searchLocation}
                                    style={{position: 'absolute', right: '7px', top: '7px', cursor: 'pointer'}}
                                    className='bx bx-search-alt bx-sm'/></div>
                        </div>
                    </form>
                </div>

                <div className='mt-3 col-md-6'>
                    <div><label className='mb-1 p-0'
                                htmlFor='geoLocationPoints'>{t('systems.form2.field3.name')} *</label></div>
                    <div className='fw-light font-size-11 text-muted mb-2'>{t('systems.form2.field3.info')}.</div>
                    <div className='d-flex'>
                        <div className='me-2' style={{maxWidth: '150px'}}>
                            <Input
                                type='number'
                                name='latitude'
                                id='latitude'
                                className='form-control'
                                value={placesState.latitude}
                                onChange={(e)=> onChangeHandler(e, true)}
                                placeholder={t("systems.form2.field3.placeholder1")}
                            />
                        </div>
                        <div className='me-2' style={{maxWidth: '150px'}}>
                            <Input
                                type='number'
                                name='longitude'
                                id='longitude'
                                className='form-control'
                                value={placesState.longitude}
                                onChange={(e)=> onChangeHandler(e, true)}
                                placeholder={t("systems.form2.field3.placeholder2")}
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center'><i onClick={addPath} style={{
                            border: '1px solid #DA1021',
                            borderRadius: '4px',
                            padding: '1px 1px',
                            color: '#DA1021',
                            cursor: 'pointer'
                        }} className='bx bx-check bx-sm'/></div>
                    </div>
                </div>
            </Row>

            <Map_with_Polygon
                strokeColor={'red'}
                formState={props.formState}
                setFormState={props.setFormState}
            />            

        </div>
    )
}


export default SystemPLT;