import React, {useEffect, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ImagePreviewModal from "../modals/ImagePreviewModal";

const DraggableImageViewer = ({imageData, setImageData, isDisabled}) => {

    const [base64Images, setBase64Images] = useState([]);
    const [imagesArr, setImagesArr] = useState([]);
    const [selectedImage, setSelectedImage] = useState("");

    useEffect(() => {
        const convertImagesToBase64 = async () => {
            const base64Array = await Promise.all(imageData.map(async (item) => {
                if (item._id) {
                    return await fetchImageAndConvertToBase64(item.url);
                } else if (item.file) {
                    // Convert file to Base64
                    return await convertToBase64(item.file);
                }
                return null;
            }));

            setBase64Images(base64Array.filter(Boolean)); // Filter out null values

            const urlArray = (
                await Promise.all(
                    imageData.map(async (item) => {
                        let url = null;
                        if (item._id) {
                            url = await fetchImageAndConvertToURL(item.url?.replace("small", "medium"));
                        } else if (item.file) {
                            url = await convertToURL(item.file);
                        }

                        return url ? { original: url } : null;
                    })
                )
            ).filter((item) => item !== null);

            setImagesArr(urlArray)
        };

        if(imageData?.length) {
            convertImagesToBase64();
        } else {
            setBase64Images([]);
        }
    }, [imageData]);

    const fetchImageAndConvertToBase64 = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return await convertToBase64(blob);
        } catch (error) {
            console.error('Error fetching image:', error);
            return null;
        }
    };

    const fetchImageAndConvertToURL = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error("Failed to fetch and convert image:", error);
            return null;
        }
    };

    const convertToURL = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const url = URL.createObjectURL(file);
                resolve(url);
            } catch (error) {
                reject(error);
            }
        });
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleImageClick = async (item) => {
        setSelectedImage(item + 1);
    };

    const deletePath = (id) => {
        let imgArr = [];
        for(let i=0; i<imageData.length; i++) {
            if(i !== id) {
                imgArr.push(imageData[i])
            }
        }

        let oldImages = JSON.parse(JSON.stringify(base64Images));
        oldImages.splice(id, 1);

        setBase64Images(oldImages)
        setImageData(imgArr)
    }

    const onDragEnd = (e) => {
        const {destination, source, draggableId} = e;

        if (!destination) {
            return;
        } else if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        } else {
            const newData = imageData;
            const element = imageData[Number(draggableId)]
            newData.splice(source.index, 1);
            newData.splice(destination.index, 0, element);

            setImageData(newData)


            const newDataBase64 = JSON.parse(JSON.stringify(base64Images));
            const elementBase64 = base64Images[Number(draggableId)]
            newDataBase64.splice(source.index, 1);
            newDataBase64.splice(destination.index, 0, elementBase64);

            setBase64Images(newDataBase64)

            const newImgUrlArr = JSON.parse(JSON.stringify(imagesArr));
            const elementURL = imagesArr[Number(draggableId)]
            newImgUrlArr.splice(source.index, 1);
            newImgUrlArr.splice(destination.index, 0, elementURL);

            setImagesArr(newImgUrlArr)

        }
    }

    return (
        <div className='col-md-6'>
            {imageData?.length ?
                <DragDropContext onDragEnd={onDragEnd}>
                    <div>
                        <Droppable droppableId="paths-system" direction="horizontal" isDropDisabled={isDisabled}>
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} className="d-flex">
                                    {base64Images.map((item, index) => {
                                        return (
                                            <Draggable
                                                draggableId={index.toString()}
                                                index={index}
                                                key={index}
                                                isDragDisabled={isDisabled}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        key={`paths-${index}`}
                                                        className='mt-2 me-2 position-relative'
                                                    >
                                                        <img
                                                            {...provided.dragHandleProps}
                                                            src={item}
                                                            alt={imageData[index]?.file ? imageData[index]?.file?.name : imageData[index]?._id}
                                                            style={{
                                                                width: 80,
                                                                height: 80,
                                                                maxWidth: 80,
                                                                maxHeight: 80,
                                                                objectFit: "cover"
                                                            }}
                                                            onClick={() => handleImageClick(index)}
                                                        />
                                                        {!isDisabled ?
                                                        <div
                                                            className='d-flex align-items-center justify-content-center'>
                                                            <i
                                                                onClick={() => deletePath(index)} style={{
                                                                top: 5,
                                                                right: 5,
                                                                color: '#FFF',
                                                                cursor: 'pointer'
                                                            }} className='bx bx-trash position-absolute'/>
                                                        </div>
                                                        :""}
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>
            :""}

            <ImagePreviewModal
                isOpen={!!selectedImage}
                setIsOpen={() => setSelectedImage("")}
                imagesData={imagesArr}
                startIndex={selectedImage - 1}
                isLoading={imagesArr?.length !== imageData?.length}
            />
        </div>
    )

}

export default DraggableImageViewer;