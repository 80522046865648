import { gardenInfoActionTypes } from "../actionTypes/index";
import produce from "immer";

const initialState = {
    gardenTypesOptions: [],
    gardenInfoData: {
        section1: null,
        section2: null,
        section3: null,
        section4: null,
        section5: null,
        section6: null,
        section7: null,
    },
};

const systemData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case gardenInfoActionTypes.GET_GARDEN_TYPES_SUCCEED: {
                state.gardenTypesOptions = payload;
                return state;
            }
            case gardenInfoActionTypes.GET_GARDEN_INFO_SECTION_DATA_SUCCEED: {
                state.gardenInfoData[payload.section] = payload.data;
                return state;
            }
            case gardenInfoActionTypes.UPDATE_GARDEN_INFO_SECTION_SUCCEED: {
                state.gardenInfoData[payload.section] = payload.data;
                return state;
            }


            default: {
                return state;
            }
        }
    }
);

export default systemData;
