import {plantCategoryActionTypes} from "../actionTypes/index";
import produce from "immer";

const initialState = {
    plantCategoryList: [],
    plantCategoryData: null,
    totalSize: 0,
    plantCategorySpeciesList: [],
    speciesTotalSize: 0
};

const plantCategoryData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case plantCategoryActionTypes.GET_PLANT_CATEGORY_LIST_SUCCEED: {
                state.plantCategoryList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case plantCategoryActionTypes.GET_PLANT_CATEGORY_DATA_SUCCEED: {
                state.plantCategoryData = payload;
                return state;
            }
            case plantCategoryActionTypes.CREATE_PLANT_CATEGORY_SUCCEED: {
                state.plantCategoryList.push(payload);
                state.totalSize++;
                return state;
            }
            case plantCategoryActionTypes.UPDATE_PLANT_CATEGORY_SUCCEED: {
                const updatedId = state.plantCategoryList.findIndex((item)=>item._id===payload._id);
                state.plantCategoryList[updatedId]=payload;
                return state;
            }

            case plantCategoryActionTypes.GET_PLANT_CATEGORY_SPECIES_LIST_SUCCEED: {
                state.plantCategorySpeciesList = payload.results;
                state.speciesTotalSize = payload.totalResults;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default plantCategoryData;
