import React from "react";
import {useTranslation} from "react-i18next";
import {Row} from "reactstrap";
import Map from "../common/Map";


const SystemPLTView = (props) => {

    const {t} = useTranslation();

    return (

        <div className='pb-3'>
            <div className='view-entity-container'>
                <Row>
                    <div className='fw-bold mb-3'>{t('systems.form2.card.topic')} *</div>
                </Row>


                <div>
                    <div>{t("systems.form2.field1.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.visibility === "public" ? t('systems.form2.field1.option1') : t('systems.form2.field1.option2')}</div>
                    </div>
                </div>

                <div>
                    <div>{t("systems.form2.field4.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.irrigation_enabled === "enabled" ? t('systems.form2.field4.option1') : t('systems.form2.field4.option2')}</div>
                    </div>
                </div>

                <div className='row mt-0 mb-0'>
                    <div className='col-md-6'>
                        <div>{t("systems.form2.field2.name")}</div>
                        <div className='mt-2'>
                            <Map
                                strokeColor={'red'}
                                isView={true}
                                polygonPaths={props.formState.paths.map((el)=>{
                                    return{
                                        lat: Number(el[0]),
                                        lng: Number(el[1])
                                    }
                                })}
                                center={props.formState.mapCenter}
                            />
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div>{t("systems.form2.field3.name")}</div>
                        <div className='mt-2'>
                            {props.formState.paths.map((el, index)=>{
                                return(
                                    <div className='row m-0' key={"system-plt-form-view-" +index}>
                                        <div className='col-4 m-0 ps-0'><div className='form-control'>{el[0]}</div></div>
                                        <div className='col-4 m-0 pe-0'><div className='form-control'>{el[1]}</div></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default SystemPLTView;