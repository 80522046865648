import {plantCategoryActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getPlantCategoriesNewList = (page, search) => async dispatch => {
    dispatch({type: plantCategoryActionTypes.GET_PLANT_CATEGORY_LIST_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/plantCategories/?page=${page}&limit=5&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: plantCategoryActionTypes.GET_PLANT_CATEGORY_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: plantCategoryActionTypes.GET_PLANT_CATEGORY_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getSinglePlantCategoryData = (id) => async dispatch => {
    dispatch({type: plantCategoryActionTypes.GET_PLANT_CATEGORY_DATA_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/plantCategories/${id}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: plantCategoryActionTypes.GET_PLANT_CATEGORY_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: plantCategoryActionTypes.GET_PLANT_CATEGORY_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addPlantCategory = (Data) => async dispatch => {
    dispatch({type: plantCategoryActionTypes.CREATE_PLANT_CATEGORY_REQUEST});

    let data = new URLSearchParams();

    data.append('name', Data.name);
    data.append('notes', Data.note);

    try {
        const res = await request({
            url: `plantsystem/ui/plantCategories`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: plantCategoryActionTypes.CREATE_PLANT_CATEGORY_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: plantCategoryActionTypes.CREATE_PLANT_CATEGORY_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updatePlantCategory = (Data) => async dispatch => {
    dispatch({type: plantCategoryActionTypes.UPDATE_PLANT_CATEGORY_REQUEST});

    let data = new URLSearchParams();

    data.append('name', Data.name);
    data.append('notes', Data.note);

    try {
        const res = await request({
            url: `plantsystem/ui/plantCategories/${Data._id}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: plantCategoryActionTypes.UPDATE_PLANT_CATEGORY_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: plantCategoryActionTypes.UPDATE_PLANT_CATEGORY_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}


export const getPlantCategorySpeciesList = (categoryId, page, search) => async dispatch => {
    dispatch({type: plantCategoryActionTypes.GET_PLANT_CATEGORY_SPECIES_LIST_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/plantSpecies/?category_id=${categoryId}&page=${page}&limit=5&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: plantCategoryActionTypes.GET_PLANT_CATEGORY_SPECIES_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: plantCategoryActionTypes.GET_PLANT_CATEGORY_SPECIES_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}


export const addPlantCategorySpecies = (Data) => async dispatch => {
    dispatch({type: plantCategoryActionTypes.CREATE_PLANT_CATEGORY_SPECIES_REQUEST});

    let data = new URLSearchParams();

    data.append('common_name', Data.common_name);
    data.append('scientific_name', Data.scientific_name);
    data.append('min_vegetation_temp', Data.min_vegetation_temp);
    data.append('max_vegetation_temp', Data.max_vegetation_temp);
    data.append('ec_max', Data.ec_max);
    data.append('ph_min', Data.ph_min);
    data.append('ph_max', Data.ph_max);
    data.append('resistance_to_frost', Data.resistance_to_frost);
    data.append('co2', Data.co2);
    data.append('pm10', Data.pm10);
    data.append('pm2_5', Data.pm2_5);
    data.append('no2', Data.no2);
    data.append('so2', Data.so2);
    data.append('o3', Data.o3);
    data.append('category_id', Data.category_id);

    try {
        const res = await request({
            url: `plantsystem/ui/plantSpecies`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: plantCategoryActionTypes.CREATE_PLANT_CATEGORY_SPECIES_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: plantCategoryActionTypes.CREATE_PLANT_CATEGORY_SPECIES_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updatePlantCategorySpecies = (Data) => async dispatch => {
    dispatch({type: plantCategoryActionTypes.UPDATE_PLANT_CATEGORY_SPECIES_REQUEST});

    let data = new URLSearchParams();


    data.append('common_name', Data.common_name);
    data.append('scientific_name', Data.scientific_name);
    data.append('min_vegetation_temp', Data.min_vegetation_temp);
    data.append('max_vegetation_temp', Data.max_vegetation_temp);
    data.append('ec_max', Data.ec_max);
    data.append('ph_min', Data.ph_min);
    data.append('ph_max', Data.ph_max);
    data.append('resistance_to_frost', Data.resistance_to_frost);
    data.append('co2', Data.co2);
    data.append('pm10', Data.pm10);
    data.append('pm2_5', Data.pm2_5);
    data.append('no2', Data.no2);
    data.append('so2', Data.so2);
    data.append('o3', Data.o3);

    try {
        const res = await request({
            url: `plantsystem/ui/plantSpecies/${Data._id}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: plantCategoryActionTypes.UPDATE_PLANT_CATEGORY_SPECIES_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: plantCategoryActionTypes.UPDATE_PLANT_CATEGORY_SPECIES_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}