import React, {useState} from "react";
import {Button, Row} from "react-bootstrap";
import {Col, Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";


const PlantCategoryForm=(props)=>{

    const { t } = useTranslation();
    const params = useParams();

    const onChangeHandler=(e)=>{
        const { name, value } = e.target;
        props.setFormState({
            ...props.formState,
            [name]: value
        })
    }


    return (
        <form onSubmit={props.onSaveClick}>
            <Row>
                <div className='mt-3'>
                    <div>
                        <label className='mb-2 p-0'
                               htmlFor='name'>{t('plantCategories.form.field1.name')} *</label>
                    </div>
                    <div>
                        <Input
                            type='text'
                            name='name'
                            id='name'
                            className='form-control'
                            value={props.formState.name}
                            onChange={onChangeHandler}
                            placeholder={t('plantCategories.form.field1.placeholder')}
                            required
                            disabled={props.isView}
                        />
                    </div>
                </div>

                <div className='mt-3'>
                    <div>
                        <label className='mb-2 p-0'
                               htmlFor='name'>{t('plantCategories.form.field2.name')}</label>
                    </div>
                    <div>
                        <Input
                            type='textarea'
                            name='note'
                            id='note'
                            className='form-control'
                            value={props.formState.note}
                            onChange={onChangeHandler}
                            placeholder={t('plantCategories.form.field2.placeholder')}
                            disabled={props.isView}
                        />
                    </div>
                </div>
            </Row>


            <div className='col-12 text-end mt-3 form-btn-grp'>
                {!props.isView ? <Button color='primary' type='submit' className='me-2'>{t('main.save')}</Button> :""}
                <Link to={`/plant-categories`}><Button type='button' className='btn-secondary'>{t(props.isView ? 'main.back' : 'main.cancel')}</Button></Link>
            </div>

        </form>
    )
}


export default PlantCategoryForm;