import React, {useEffect, useState} from "react";
import {Card, CardBody, Input} from "reactstrap";
import { useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {Button, Form, Row} from "react-bootstrap";
import {updateGardenInfoSectionData} from "../../redux/actions/gardenInfoActions";

const GardenInfoCard4=({isDisabled})=>{

    const params = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const sectionData = useSelector((el)=> el.gardenInfoData.gardenInfoData?.section4)

    const initialFormValue = {
        property_type: "public",
        phone_number: "",
        email: "",
        company_address: "",
        notes: ""
    }

    const [formState, setFormState] = useState(initialFormValue)

    useEffect(() => {
        if(sectionData && sectionData?.contact_reference) {
            setFormState({
                ...initialFormValue,
                ...sectionData?.contact_reference
            })
        }
    }, [JSON.stringify(sectionData)]);

    const onSaveClick = (e) => {
        e.preventDefault();

        dispatch(updateGardenInfoSectionData(formState, params.systemId, 4))
    }

    const onChangeHandler= (e) => {
        const {name, value} = e.target;
        if((name === "notes" && value.length <= 500) || name !== "notes") {
            setFormState({
                ...formState,
                [name]: value
            })
        }
    }

    return(
        <Card>
            <CardBody>
                <div>
                    <div className='font-size-15 fw-medium'>{t("gardenInfo.card4.topic")}</div>
                </div>
                <form onSubmit={onSaveClick}>
                    <Row>
                        <div className="mt-3">
                            <div className="d-flex">
                                <Form.Check
                                    type="radio"
                                    value="public"
                                    id="property_type-public"
                                    name="property_type"
                                    label={t('gardenInfo.card4.form1.value1')}
                                    onChange={onChangeHandler}
                                    checked={formState.property_type === 'public'}
                                    className="me-4"
                                    disabled={isDisabled}
                                />
                                <Form.Check
                                    type="radio"
                                    value="private"
                                    id="property_type-private"
                                    name="property_type"
                                    label={t('gardenInfo.card4.form1.value2')}
                                    onChange={onChangeHandler}
                                    checked={formState.property_type === 'private'}
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='phone_number'>{t('gardenInfo.card4.form2.name')}</label>
                            </div>
                            <Input
                                type='text'
                                name='phone_number'
                                id='phone_number'
                                className='form-control'
                                value={formState.phone_number}
                                onChange={onChangeHandler}
                                disabled={isDisabled}
                            />
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0' htmlFor='email'>{t('gardenInfo.card4.form3.name')}</label>
                            </div>
                            <Input
                                type='email'
                                name='email'
                                id='email'
                                className='form-control'
                                value={formState.email}
                                onChange={onChangeHandler}
                                disabled={isDisabled}
                            />
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='company_address'>{t('gardenInfo.card4.form4.name')}</label>
                            </div>
                            <Input
                                type='text'
                                name='company_address'
                                id='company_address'
                                className='form-control'
                                value={formState.company_address}
                                onChange={onChangeHandler}
                                disabled={isDisabled}
                            />
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='notes'>{t('gardenInfo.card4.form5.name')}</label></div>
                            <div className='form-limitation-container'>
                                <Input
                                    type='textarea'
                                    name='notes'
                                    id='notes'
                                    className='form-control'
                                    value={formState.notes}
                                    onChange={onChangeHandler}
                                    placeholder={t('gardenInfo.card4.form5.placeholder')}
                                    disabled={isDisabled}
                                />
                                <div>{formState.notes.length}/500</div>
                            </div>
                        </div>

                    </Row>

                    {!isDisabled ?
                        <div className='col-12 text-end mt-3 form-btn-grp'>
                            <Button color='primary' type='submit' className='me-2'>{t('main.save')}</Button>
                        </div>
                    :""}

                </form>
            </CardBody>
        </Card>
    )
}

export default GardenInfoCard4;