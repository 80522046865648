import React, {useEffect, useState} from "react";
import {Card, CardBody, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

import {ReactComponent as Plant} from '../../assets/icons/plant-wilt.svg'

import Layout from "../../layout/layout";
import TableFirst from "../../components/tables/TableFirst"
import SearchBar from "../../components/others/SearchBar";
import Breadcrumb from "../../components/common/Breadcrumb";

import {getPlantCategoriesNewList} from "../../redux/actions/plantCategoryActions";

const PlantCategoriesList = () => {

    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();

    const role = useSelector((state) => state.authData?.userDetails?.role)
    const plantCategoryData = useSelector((el)=> el.plantCategoryData)

    const [plantCategorySearch, setPlantCategorySearch] = useState()

    useEffect(() => {
        dispatch(getPlantCategoriesNewList(1, null))
    }, [])

    const getDataList=(page)=>{
        dispatch(getPlantCategoriesNewList(page, plantCategorySearch ? plantCategorySearch : null))
    }

    const onPlantCategorySearch=()=>{
        dispatch(getPlantCategoriesNewList(1, plantCategorySearch))
    }

    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            headerStyle: {backgroundColor: 'white'},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex">
                        <div className="me-3 d-flex align-items-center">
                            <Plant className='pe-4 tap-icon'/>
                        </div>
                        <div>
                            <div className='text-new-dark fw-bold font-size-13'>{row.name}</div>
                            <div className='fw-light text-semi-dark font-size-12'>{t("main.lastUpdate")}: {moment(row.updatedAt).format('YY/MM/DD HH:mm')}</div>
                        </div>
                    </div>)
            }
        },
        {
            key: "actions",
            text: "",
            dataField: "",
            style: {textAlign: 'right'},
            headerStyle: {backgroundColor: 'white', boxShadow: '0'},
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <Link to={`/plant-categories/view/${row._id}`}><Button color="primary">{t("main.view")}</Button></Link>
                        <Link to={`/plant-categories/edit/${row._id}`}><Button color="warning" className="ms-2">{t("main.edit")}</Button></Link>
                    </div>);
            }
        }
    ];

    return (
      <Layout>
        <div>
          <Breadcrumb
            title={t("plantCategories.title")}
            breadcrumbItems={[
              {
                path: `plant-categories`,
                name: t("plantCategories.breadcrumb1"),
              },
            ]}
          />
          <Card>
            <CardBody>
              <div>
                <SearchBar
                  name="plantCategories"
                  btnName={t("plantCategories.add")}
                  link={`plant-categories`}
                  searchValue={plantCategorySearch}
                  setSearchValue={setPlantCategorySearch}
                  onSearch={onPlantCategorySearch}
                />
                  <div className='text-semi-dark ms-2 mb-4' style={{marginTop: -10}}>{t("plantCategories.description")}</div>
              </div>
              <TableFirst
                dataList={plantCategoryData.plantCategoryList}
                columns={tableColumns}
                total={plantCategoryData.totalSize}
                getDataList={getDataList}
                containerClass={"header-less-table-container"}
              />
            </CardBody>
          </Card>
        </div>
      </Layout>
    );
}

export default PlantCategoriesList;
