import {Button, Card, CardBody, Input} from "reactstrap";
import Select from "react-select";
import React from "react";
import {useTranslation} from "react-i18next";

import SearchSelect from "../common/SearchSelect";

import { ReactComponent as History } from '../../assets/icons/history.svg'

const IrrigationSectorCard = ({item, index, onChangeHandlerSection, switchDeviceListData, moistureDeviceListData, customStyles, onResetClick, onDeleteSectorClick, onChartClick, onHourRangesClick}) => {

    const {t} = useTranslation();

    const formatTimeSlot = (times) => {
        if (times.length === 0) return t("irrigationManagement.sector.hour.na");
        else if (times.length === 24) return t("irrigationManagement.sector.hour.allDay");

        const sortedTimes = times.map(time => parseInt(time, 10)).sort((a, b) => a - b);

        let ranges = [];
        let start = sortedTimes[0];
        let end = sortedTimes[0];

        for (let i = 1; i < sortedTimes.length; i++) {
            if (sortedTimes[i] === end + 1) {
                end = sortedTimes[i];
            } else {
                ranges.push(`${start.toString().padStart(2, '0')}-${(end + 1).toString().padStart(2, '0')}`);
                start = sortedTimes[i];
                end = sortedTimes[i];
            }
        }

        ranges.push(`${start.toString().padStart(2, '0')}-${(end + 1).toString().padStart(2, '0')}`);

        return ranges.join(", ");
    }

    return (
        <Card>
            <CardBody>
                <div>
                    <div
                        className='font-size-15 fw-medium'>{item.name}</div>
                </div>

                <div className="row">
                    <div className='mt-3 col-md-7'>
                        <form className="row">
                            <div className='mt-3 col-md-6'>
                                <div><label className='mb-2 p-0'
                                            htmlFor='name'>{t('irrigationManagement.sector.form1.name')} *</label>
                                </div>
                                <div className='form-limitation-container'>
                                    <Input
                                        type='text'
                                        name='name'
                                        id='name'
                                        className='form-control'
                                        value={item.name}
                                        onChange={(e) => onChangeHandlerSection(e, index)}
                                    />
                                    <div>{item.name.length}/50</div>
                                </div>
                            </div>
                            <div className='mt-3 col-6 col-md-3'>
                                <div><label className='mb-2 p-0'
                                            htmlFor='minv_sm'>{t('irrigationManagement.sector.form2.name')} *</label>
                                </div>
                                <Input
                                    type='number'
                                    inputMode="numeric"
                                    name='minv_sm'
                                    id='minv_sm'
                                    className='form-control'
                                    value={item.minv_sm}
                                    onChange={(e) => onChangeHandlerSection(e, index)}
                                />
                            </div>
                            <div className='mt-3 col-6 col-md-3'>
                                <div><label className='mb-2 p-0'
                                            htmlFor='maxv_sm'>{t('irrigationManagement.sector.form3.name')} *</label>
                                </div>
                                <Input
                                    type='number'
                                    inputMode="numeric"
                                    name='maxv_sm'
                                    id='maxv_sm'
                                    className='form-control'
                                    value={item.maxv_sm}
                                    onChange={(e) => onChangeHandlerSection(e, index)}
                                />
                            </div>

                            <div className="col-md-6 mt-3">
                                <div><label className="mb-2 p-0"
                                            htmlFor="valve_device_slug_id">{t("irrigationManagement.sector.form4.name")} *</label>
                                </div>
                                <SearchSelect
                                    name="valve_device_slug_id"
                                    data={switchDeviceListData.map((el) => {
                                        return {
                                            value: el.slug_id,
                                            label: el.name,
                                        };
                                    })}
                                    value={
                                        item?.valve_device_slug_id
                                            ? {
                                                value: item?.valve_device_slug_id,
                                                label: switchDeviceListData.find(
                                                    (el) => el.slug_id === item?.valve_device_slug_id
                                                )?.name,
                                            }
                                            : null
                                    }
                                    onChangeHandler={(val) => {
                                        const e = {
                                            target: {
                                                name: 'valve_device_slug_id',
                                                value: val.value
                                            }
                                        }
                                        onChangeHandlerSection(e, index);
                                    }}
                                />
                            </div>

                            <div className='mt-3 col-6 col-md-3'>
                                <div><label className='mb-2 p-0'
                                            htmlFor='irrigation_threshold_value'>{t('irrigationManagement.sector.form5.name')} *</label>
                                </div>
                                <Input
                                    type='number'
                                    inputMode="numeric"
                                    name='irrigation_threshold_value'
                                    id='irrigation_threshold_value'
                                    className='form-control'
                                    value={item.irrigation_threshold_value}
                                    onChange={(e) => onChangeHandlerSection(e, index)}
                                />
                            </div>

                            <div className="mt-3 col-12">
                                <div><label className="mb-2 p-0"
                                            htmlFor="sm_devices">{t("irrigationManagement.sector.form6.name")} *</label>
                                </div>
                                <div>
                                    <Select
                                        isMulti
                                        name="sm_devices"
                                        options={moistureDeviceListData.map((el) => {
                                            return {
                                                label: el.name,
                                                value: el.slug_id
                                            }
                                        })}
                                        className="basic-multi-select"
                                        components={{IndicatorSeparator: () => null}}
                                        isClearable={false}
                                        isOptionDisabled={() => item?.sm_devices?.length >= 50}
                                        onChange={(val) => {
                                            const e = {
                                                target: {
                                                    name: 'sm_devices',
                                                    value: val
                                                }
                                            }
                                            onChangeHandlerSection(e, index)
                                        }}
                                        value={item?.sm_devices?.map((el) => ({
                                            value: el?._id ? el.slug_id : el.value,
                                            label: el?._id ? el.name : el.label,
                                        }))}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>

                            <div className='mt-3 col-6 col-md-3'>
                                <div><label className='mb-2 p-0'
                                            htmlFor='minutes_irrigation_time'>{t('irrigationManagement.sector.form7.name')} *</label>
                                </div>
                                <Input
                                    type='number'
                                    inputMode="numeric"
                                    name='minutes_irrigation_time'
                                    id='minutes_irrigation_time'
                                    className='form-control'
                                    value={item.minutes_irrigation_time}
                                    onChange={(e) => onChangeHandlerSection(e, index)}
                                />
                            </div>

                            <div className='mt-3 col-6 col-md-3'>
                                <div><label className='mb-2 p-0'
                                            htmlFor='minutes_stop_time'>{t('irrigationManagement.sector.form8.name')} *</label>
                                </div>
                                <Input
                                    type='number'
                                    inputMode="numeric"
                                    name='minutes_stop_time'
                                    id='minutes_stop_time'
                                    className='form-control'
                                    value={item.minutes_stop_time}
                                    onChange={(e) => onChangeHandlerSection(e, index)}
                                />
                            </div>

                            <div className='mt-3 col-6 col-md-6'>
                                <div><label className='mb-2 p-0'
                                            htmlFor='max_irrigation_cycles'>{t('irrigationManagement.sector.form9.name')} *</label>
                                </div>
                                <Input
                                    type='number'
                                    inputMode="numeric"
                                    name='max_irrigation_cycles'
                                    id='max_irrigation_cycles'
                                    className='form-control'
                                    value={item.max_irrigation_cycles}
                                    onChange={(e) => onChangeHandlerSection(e, index)}
                                />
                            </div>

                            <div className="mt-4">
                                <div className='mt-3 col-6 col-md-6'>
                                    <div className='mb-2 p-0'>{t('irrigationManagement.sector.field1')}: <span
                                        className="text-new-dark">{item?.sm_avg_value ?? "-"}</span></div>
                                </div>

                                <div className='mt-3 col-6 col-md-6'>
                                    <div className='mb-2 p-0'>{t('irrigationManagement.sector.field2')}: <span
                                        className="text-new-dark">{item?.execution_rule_entity?.state?.value ?? "-"}</span>
                                        {item?.execution_rule_entity ?
                                            <span className="ms-4" style={{cursor: "pointer"}}><History
                                                onClick={() => onChartClick(item?.execution_rule_entity)}/></span> : ""}
                                    </div>
                                </div>

                                <div className='mt-3 col-6 col-md-6'>
                                    <div className='mb-2 p-0'>{t('irrigationManagement.sector.field3')}: <span
                                        className="text-new-dark">{item?.avg_value_rule_entity?.state?.value ?? "-"}</span>
                                        {item?.avg_value_rule_entity ?
                                            <span className="ms-4" style={{cursor: "pointer"}}><History
                                                onClick={() => onChartClick(item?.avg_value_rule_entity)}/></span> : ""}
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div style={{maxWidth: 500}} className='mt-3 mx-auto border px-4 py-4 col-md-5 d-flex flex-column justify-content-between'>
                        <div className='fw-medium font-size-14'>{t("irrigationManagement.sector.hour.topic")}</div>

                        <div className="mt-5">
                            <div className="d-flex mt-3">
                                <div style={{minWidth: 150}}>{t("irrigationManagement.sector.hour.monday")}</div>
                                <div>{formatTimeSlot(item?.stop_irrigations_hour_ranges?.monday ?? [])}</div>
                            </div>

                            <div className="d-flex mt-3">
                                <div style={{minWidth: 150}}>{t("irrigationManagement.sector.hour.tuesday")}</div>
                                <div>{formatTimeSlot(item?.stop_irrigations_hour_ranges?.tuesday ?? [])}</div>
                            </div>

                            <div className="d-flex mt-3">
                                <div style={{minWidth: 150}}>{t("irrigationManagement.sector.hour.wednesday")}</div>
                                <div>{formatTimeSlot(item?.stop_irrigations_hour_ranges?.wednesday ?? [])}</div>
                            </div>

                            <div className="d-flex mt-3">
                                <div style={{minWidth: 150}}>{t("irrigationManagement.sector.hour.thursday")}</div>
                                <div>{formatTimeSlot(item?.stop_irrigations_hour_ranges?.thursday ?? [])}</div>
                            </div>

                            <div className="d-flex mt-3">
                                <div style={{minWidth: 150}}>{t("irrigationManagement.sector.hour.friday")}</div>
                                <div>{formatTimeSlot(item?.stop_irrigations_hour_ranges?.friday ?? [])}</div>
                            </div>

                            <div className="d-flex mt-3">
                                <div style={{minWidth: 150}}>{t("irrigationManagement.sector.hour.saturday")}</div>
                                <div>{formatTimeSlot(item?.stop_irrigations_hour_ranges?.saturday ?? [])}</div>
                            </div>

                            <div className="d-flex mt-3">
                                <div style={{minWidth: 150}}>{t("irrigationManagement.sector.hour.sunday")}</div>
                                <div>{formatTimeSlot(item?.stop_irrigations_hour_ranges?.sunday ?? [])}</div>
                            </div>
                        </div>

                        <div className="mt-auto text-end">
                            <Button onClick={() => onHourRangesClick(item)} color='primary'>{t("irrigationManagement.sector.modify")}</Button>
                        </div>
                    </div>

                </div>

                <div className="mt-3 d-md-flex justify-content-between">
                    <div />
                    <Button onClick={() => onResetClick(index)} className="btn-secondary btn-reset-default me-2 me-md-0">{t('irrigationManagement.sector.reset')}</Button>
                    <Button onClick={() => onDeleteSectorClick(index)} color='danger'>{t('irrigationManagement.sector.delete')}</Button>
                </div>
            </CardBody>
        </Card>
    )
}

export default IrrigationSectorCard;