import React, {useState, useEffect, useRef} from "react";
import {compose, withProps} from "recompose"
import {withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon} from "react-google-maps"
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import { config } from "../../config";

const key = config.GOOGLE_API_KEY;

const Map = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `300px`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap
)((props) => {

    const polygonRef = useRef(null)
    const systemPolygonRef = useRef(null)
    const markerRef = useRef(null)
    const mapRef = useRef(null)

    const [paths, setPaths] = useState([])
    const [center, setCenter] = useState(null)
    const [isMarkerShowing, setIsMarkerShowing] = useState(false)

    useEffect(()=>{
        setTimeout(()=>{
            if(mapRef.current && polygonRef.current){
                let bounds = new window.google.maps.LatLngBounds();

                let polPaths = polygonRef.current.getPath().getArray()
                for (let i=0; i<polPaths.length; i++) {
                    let point = new window.google.maps.LatLng(polPaths[i].lat(), polPaths[i].lng());
                    bounds.extend(point);
                }
                mapRef.current.fitBounds(bounds)
            } else if(mapRef.current && markerRef.current){
                let position = markerRef.current.getPosition();
                setCenter({
                    lat: position.lat(),
                    lng: position.lng()
                })
            }

            if(mapRef.current && systemPolygonRef.current){
                let bounds = new window.google.maps.LatLngBounds();

                let polPaths = systemPolygonRef.current.getPath().getArray()
                for (let i=0; i<polPaths.length; i++) {
                    let point = new window.google.maps.LatLng(polPaths[i].lat(), polPaths[i].lng());
                    bounds.extend(point);
                }
                mapRef.current.fitBounds(bounds)
            }
        },1000)
    },[])

    useEffect(() => {
        setPaths(props.polygonPaths)
    }, [JSON.stringify(props.polygonPaths)])

    useEffect(() => {
        setCenter(props.center)
        if(!props.isMarkerDisabled) {
            setIsMarkerShowing(true)
        }
    }, [props.center])

    const onPolygonComplete = (e) => {
        let pathArray = [];
        var coordinates = (e.getPath().getArray());

        for (let i = 0; i < coordinates.length; i++) {
            let lat = coordinates[i].lat();
            let lng = coordinates[i].lng();
            pathArray.push({
                lat: lat,
                lng: lng
            })
        }
        props.addNewPaths(pathArray);
        e.setMap(null)
    }

    const onPathEditEnd = (e) => {
        let pathObjArray = polygonRef.current.getPath().getArray();
        let pathsArray = [];

        for(let i=0; i<pathObjArray.length; i++){
            pathsArray.push({
                lat: pathObjArray[i].lat(),
                lng: pathObjArray[i].lng(),
            })
        }
        props.addNewPaths(pathsArray);
    }

    const onPositionChanged=()=>{
        let marker = {};
        marker.lat = markerRef.current.getPosition().lat();
        marker.lng = markerRef.current.getPosition().lng();

        props.updateMarker(marker)
    }

    const onCenterChanged=()=>{
        setCenter({
            lat: mapRef.current.getCenter().lat(),
            lng: mapRef.current.getCenter().lng(),
        })
    }

    return (
        <GoogleMap
            ref={mapRef}
            zoom={9}
            onCenterChanged={onCenterChanged}
            center={center ? center : {lat: 43.7228, lng: 10.4017}}
            defaultCenter={props.defaultCenter}
        >
            {!props.disablePolygon && !props.isView &&
                <DrawingManager
                    options={{
                        drawingControlOptions: {
                            drawingModes: ['polygon']
                        }
                    }}
                    onPolygonComplete={onPolygonComplete}
                />
            }

            {
                (props.center && center && isMarkerShowing) &&
                <Marker
                    position={props.center ? props.center : {lat: 43.7228, lng: 10.4017}}
                />
            }

            {props.marker &&
                <Marker
                    ref={markerRef}
                    onPositionChanged={onPositionChanged}
                    draggable={!props.isView}
                    position={props.marker}
                />
            }

            {(!props.disablePolygon && paths && paths.length > 2) &&
                <Polygon
                    ref={polygonRef}
                    path={paths}
                    options={{
                        strokeColor: props.strokeColor,
                        fillColor: props.strokeColor,
                        strokeWeight: '1'
                    }}
                    editable={!props.isView}
                    onMouseUp={props.isView ? null :onPathEditEnd}
                />
            }

            {(props.systemPolygon && props.systemPolygon.length) &&
                <Polygon
                    ref={systemPolygonRef}
                    path={props.systemPolygon}
                    options={{
                        strokeColor: "black",
                        fillColor: "black",
                        strokeWeight:"0.1",
                        fillOpacity:"0.1"
                    }}
                    editable={false}
                />
            }
        </GoogleMap>
    )
})
export default Map;