import {ruleActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getAvailableRules = () => async dispatch => {
    dispatch({type: ruleActionTypes.GET_AVAILABLE_RULES_REQUEST})

    try {
        const res = await request({
            url: `rulesystem/ui/rules/available`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: ruleActionTypes.GET_AVAILABLE_RULES_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: ruleActionTypes.GET_AVAILABLE_RULES_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getRuleList = (id, page, search) => async dispatch => {
    dispatch({type: ruleActionTypes.GET_RULE_CONFIG_LIST_REQUEST})

    try {
        const res = await request({
            url: `rulesystem/ui/ruleconfigurations?system=${id}&page=${page}&limit=5&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: ruleActionTypes.GET_RULE_CONFIG_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: ruleActionTypes.GET_RULE_CONFIG_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getRuleData = (id, systemId) => async dispatch => {
    dispatch({type: ruleActionTypes.GET_RULE_CONFIG_DATA_REQUEST})

    try {
        const res = await request({
            url: `rulesystem/ui/ruleconfigurations/${id}?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: ruleActionTypes.GET_RULE_CONFIG_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: ruleActionTypes.GET_RULE_CONFIG_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addRule = (Data, id) => async dispatch => {
    dispatch({type: ruleActionTypes.CREATE_RULE_CONFIG_REQUEST});

    // let data = new FormData();
    let data = new URLSearchParams();

    data.append('name', Data.name);
    data.append('description', Data.description);
    data.append('rule', Data.rule);
    data.append('triggerparams', JSON.stringify(Data.triggerparams));
    data.append('ruleparams', JSON.stringify(Data.ruleparams));

    try {
        const res = await request({
            url: `rulesystem/ui/ruleconfigurations?system=${id}`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: ruleActionTypes.CREATE_RULE_CONFIG_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: ruleActionTypes.CREATE_RULE_CONFIG_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updateRule = (Data, systemId) => async dispatch => {
    dispatch({type: ruleActionTypes.UPDATE_RULE_CONFIG_REQUEST});

    // let data = new FormData();
    let data = new URLSearchParams();

    data.append('name', Data.name);
    data.append('description', Data.description);
    // data.append('rule', Data.rule);
    data.append('triggerparams', JSON.stringify(Data.triggerparams));
    data.append('ruleparams', JSON.stringify(Data.ruleparams));

    try {
        const res = await request({
            url: `rulesystem/ui/ruleconfigurations/${Data._id}?system=${systemId}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: ruleActionTypes.UPDATE_RULE_CONFIG_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: ruleActionTypes.UPDATE_RULE_CONFIG_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const deleteRule = (id, systemId) => async dispatch => {
    dispatch({type: ruleActionTypes.DELETE_RULE_CONFIG_REQUEST});

    try {
        const res = await request({
            url: `rulesystem/ui/ruleconfigurations/${id}?system=${systemId}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: ruleActionTypes.DELETE_RULE_CONFIG_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: ruleActionTypes.DELETE_RULE_CONFIG_FAILED,
            payload: err ? err.data : null
        });
    }
}
