import {dashboardActionTypes} from "../actionTypes/index";
import produce from "immer";
import {config} from "../../config";

const initialState = {
    dashboardDataPLT: {
        devicesMap: [],
        gardenMap: {
            areas: [],
            garden: null
        },
        lastNotification:[],
        plantsMap: [],
        indexesData: [],
        systemHealth: null,
        userDetails: null
    },
    dashboardDataVLM: {
        shipMap: {
            devicesMap: [],
            topView: null,
            sideView: null,
        },
        lastNotification: [],
        systemHealth: null,
        userDetails: null,
        tempData: null,
        torsionData: [],
        mainDeviceData: null
    },
    areasData: [],
    onboardDevicesData:[],
    isFetching: true,
    dashboardAlertData:[],
    systemId: null
};

const dashboardData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;
        const TARGET_CODE = config.TARGET_CODE;

        switch (type) {
            case dashboardActionTypes.GET_DASHBOARD_DATA_REQUEST: {
                return initialState;
            }
            case dashboardActionTypes.GET_DASHBOARD_DATA_SUCCEED: {
                if(TARGET_CODE === 'PLT'){
                    if(payload.systemId === state.systemId){
                        state.areasData = payload.areaData;
                    }
                } else {
                    state.onboardDevicesData = payload;
                }
                state.isFetching = false;
                return state;
            }
            case dashboardActionTypes.GET_LAST_NOTIFICATION_LIST_SUCCEED: {
                if(TARGET_CODE === 'PLT'){
                    state.dashboardDataPLT.lastNotification = payload;
                } else {
                    state.dashboardDataVLM.lastNotification = payload;
                }
                return state;
            }
            case dashboardActionTypes.GET_SYSTEM_HEALTH_DETAILS_SUCCEED: {
                if(TARGET_CODE === 'PLT'){
                    state.dashboardDataPLT.systemHealth = payload
                } else {
                    state.dashboardDataVLM.systemHealth = payload;
                }
                return state;
            }
            case dashboardActionTypes.GET_TEMPERATURE_DETAILS_SUCCEED: {
                state.dashboardDataVLM.tempData = payload;
                return state;
            }
            case dashboardActionTypes.GET_ONBOARD_DEVICES_DETAILS_SUCCEED: {
                state.onboardDevicesData = payload;
                return state;
            }
            case dashboardActionTypes.GET_SHIP_MAP_DETAILS_SUCCEED: {
                state.dashboardDataVLM.shipMap.devicesMap = payload;
                return state;
            }
            case dashboardActionTypes.GET_MAIN_DEVICE_CHART_DATA_SUCCEED: {
                state.dashboardDataVLM.mainDeviceData = payload;
                return state;
            }
            case dashboardActionTypes.GET_TORSION_CHART_DATA_SUCCEED: {
                state.dashboardDataVLM.torsionData = payload;
                return state;
            }
            case dashboardActionTypes.GET_HUM_CHART_DATA_SUCCEED: {
                const updatedId = state.areasData.findIndex((item)=>item._id===payload._id);
                state.areasData[updatedId].humChart = payload.data;
                return state;
            }
            case dashboardActionTypes.GET_TEMP_CHART_DATA_SUCCEED: {
                const updatedId = state.areasData.findIndex((item)=>item._id===payload._id);
                state.areasData[updatedId].tempChart = payload.data;
                return state;
            }
            case dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_SUCCEED: {
                if(TARGET_CODE === 'PLT'){
                    state.dashboardDataPLT = {
                        ...payload,
                        indexesData: state.dashboardDataPLT.indexesData
                    };
                } else {
                    state.dashboardDataVLM = payload;
                }
                state.systemId = payload.systemId;
                return state;
            }
            case dashboardActionTypes.GET_DASHBOARD_ALERT_DATA_SUCCEED: {
                state.dashboardAlertData = payload;
                return state;
            }
            case dashboardActionTypes.CREATE_DASHBOARD_ALERT_SUCCEED: {
                state.dashboardAlertData.push(payload);
                return state;
            }
            case dashboardActionTypes.UPDATE_DASHBOARD_ALERT_SUCCEED: {
                const updatedId = state.dashboardAlertData.findIndex((item)=>item._id===payload._id);
                state.dashboardAlertData[updatedId]=payload;
                return state;
            }
            case dashboardActionTypes.DELETE_DASHBOARD_ALERT_SUCCEED: {
                const deletedId = state.dashboardAlertData.findIndex((item)=>item._id===payload._id);
                state.dashboardAlertData.splice(deletedId,1);
                return state;
            }

            case dashboardActionTypes.GET_INDEXES_DATA_SUCCEED: {
                state.dashboardDataPLT.indexesData = payload;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default dashboardData;
