export const GET_REGISTER_ENTRIES_CATEGORIES_LIST_REQUEST = "GET_REGISTER_ENTRIES_CATEGORIES_LIST_REQUEST";
export const GET_REGISTER_ENTRIES_CATEGORIES_LIST_SUCCEED = "GET_REGISTER_ENTRIES_CATEGORIES_LIST_SUCCEED";
export const GET_REGISTER_ENTRIES_CATEGORIES_LIST_FAILED = "GET_REGISTER_ENTRIES_CATEGORIES_LIST_FAILED";

export const GET_INTERVENTION_TYPES_LIST_REQUEST = "GET_INTERVENTION_TYPES_LIST_REQUEST";
export const GET_INTERVENTION_TYPES_LIST_SUCCEED = "GET_INTERVENTION_TYPES_LIST_SUCCEED";
export const GET_INTERVENTION_TYPES_LIST_FAILED = "GET_INTERVENTION_TYPES_LIST_FAILED";

export const GET_REGISTER_ENTRIES_LIST_REQUEST = "GET_REGISTER_ENTRIES_LIST_REQUEST";
export const GET_REGISTER_ENTRIES_LIST_SUCCEED = "GET_REGISTER_ENTRIES_LIST_SUCCEED";
export const GET_REGISTER_ENTRIES_LIST_FAILED = "GET_REGISTER_ENTRIES_LIST_FAILED";

export const GET_PLANT_REGISTER_ENTRIES_LIST_REQUEST = "GET_PLANT_REGISTER_ENTRIES_LIST_REQUEST";
export const GET_PLANT_REGISTER_ENTRIES_LIST_SUCCEED = "GET_PLANT_REGISTER_ENTRIES_LIST_SUCCEED";
export const GET_PLANT_REGISTER_ENTRIES_LIST_FAILED = "GET_PLANT_REGISTER_ENTRIES_LIST_FAILED";

export const GET_REGISTER_ENTRY_DATA_REQUEST = "GET_REGISTER_ENTRY_DATA_REQUEST";
export const GET_REGISTER_ENTRY_DATA_SUCCEED = "GET_REGISTER_ENTRY_DATA_SUCCEED";
export const GET_REGISTER_ENTRY_DATA_FAILED = "GET_REGISTER_ENTRY_DATA_FAILED";

export const GET_REGISTER_ENTRY_IMAGE_DATA_REQUEST = "GET_REGISTER_ENTRY_IMAGE_DATA_REQUEST";
export const GET_REGISTER_ENTRY_IMAGE_DATA_SUCCEED = "GET_REGISTER_ENTRY_IMAGE_DATA_SUCCEED";
export const GET_REGISTER_ENTRY_IMAGE_DATA_FAILED = "GET_REGISTER_ENTRY_IMAGE_DATA_FAILED";

export const CREATE_REGISTER_ENTRY_REQUEST = "CREATE_REGISTER_ENTRY_REQUEST";
export const CREATE_REGISTER_ENTRY_SUCCEED = "CREATE_REGISTER_ENTRY_SUCCEED";
export const CREATE_REGISTER_ENTRY_FAILED = "CREATE_REGISTER_ENTRY_FAILED";

export const UPDATE_REGISTER_ENTRY_REQUEST = "UPDATE_REGISTER_ENTRY_REQUEST";
export const UPDATE_REGISTER_ENTRY_SUCCEED = "UPDATE_REGISTER_ENTRY_SUCCEED";
export const UPDATE_REGISTER_ENTRY_FAILED = "UPDATE_REGISTER_ENTRY_FAILED";

export const DELETE_REGISTER_ENTRY_REQUEST = "DELETE_REGISTER_ENTRY_REQUEST";
export const DELETE_REGISTER_ENTRY_SUCCEED = "DELETE_REGISTER_ENTRY_SUCCEED";
export const DELETE_REGISTER_ENTRY_FAILED = "DELETE_REGISTER_ENTRY_FAILED";