import React, {useEffect, useState} from "react";
import {Button, Input, Modal, ModalBody, ModalHeader} from "reactstrap";
import {CloseButton, ModalFooter} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

import {ReactComponent as Seedling} from '../../assets/icons/seedling.svg'
import TableFirst from "../tables/TableFirst";


function IrrigationSectorModal({modalShow, setModalShow, onFormSubmit, sectorData}) {

    const {t} = useTranslation();

    const staticTableValues = [
        { label: t("irrigationManagement.sector.modal.table.row1"), _id: "monday", values: [] },
        { label: t("irrigationManagement.sector.modal.table.row2"), _id: "tuesday", values: [] },
        { label: t("irrigationManagement.sector.modal.table.row3"), _id: "wednesday", values: [] },
        { label: t("irrigationManagement.sector.modal.table.row4"), _id: "thursday", values: [] },
        { label: t("irrigationManagement.sector.modal.table.row5"), _id: "friday", values: [] },
        { label: t("irrigationManagement.sector.modal.table.row6"), _id: "saturday", values: [] },
        { label: t("irrigationManagement.sector.modal.table.row7"), _id: "sunday", values: [] },
    ];

    const staticSelectAllValues = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    };

    const params = useParams();

    const [tableValues, setTableValues] = useState([])
    const [tableSelectAllValues, setTableSelectAllValues] = useState(staticSelectAllValues);
    const [isSaveForAll, setIsSaveForAll] = useState(false)


    useEffect(() => {
        if(sectorData) {
            const selectAllData = JSON.parse(JSON.stringify(staticSelectAllValues));
            const modifiedData = staticTableValues.map((el) => {
                if(sectorData?.[el._id]?.length === 24){
                    selectAllData[el._id] = true;
                } else if (sectorData?.[el._id]?.length > 0) {
                    selectAllData[el._id] = null;
                } else {
                    selectAllData[el._id] = false;
                }

                return {
                    _id: el._id,
                    label: el.label,
                    values: sectorData?.[el._id] ?? []
                }
            })
            setTableSelectAllValues(selectAllData);
            setTableValues(modifiedData);
        } else {
            setTableValues(staticTableValues);
            setTableSelectAllValues(staticSelectAllValues);
        }
    }, [JSON.stringify(sectorData)])

    const toggleModal = () => {
        setModalShow(!modalShow)
    }

    const onChangeHourlyRanges = (e, id, day) => {
        const {name, checked} = e.target;

        const prevData = JSON.parse(JSON.stringify(tableValues));
        let isAllSelected;

        if(checked) {
            const prevValueArr = JSON.parse(JSON.stringify(prevData[id]?.values ?? []))
            prevValueArr.push(name);

            prevData[id].values = prevValueArr;
            isAllSelected = prevValueArr?.length === 24 ? true : null
        } else {
            const prevValueArr = JSON.parse(JSON.stringify(prevData[id]?.values ?? []))
            const deleteIndex = prevValueArr.findIndex((el) => el === name);

            prevValueArr.splice(deleteIndex, 1);
            prevData[id].values = prevValueArr;
            isAllSelected = prevValueArr?.length === 0 ? false : null
        }
        setTableValues(prevData)
        setTableSelectAllValues({...tableSelectAllValues, [day]: isAllSelected})
    }

    const onChangeSelectAll = (e, id) => {
        const {name, checked} = e.target;

        const prevData = JSON.parse(JSON.stringify(tableValues));

        if(checked) {
            prevData[id].values = Array.from({length: 24}, (_, index) => index).map((el) => {
                return el.toString().padStart(2, '0');
            });
        } else {
            prevData[id].values = [];
        }

        setTableSelectAllValues({
            ...tableSelectAllValues,
            [name]: checked
        })
        setTableValues(prevData)
    }

    const onSaveClick = () => {

        const values = {
            monday: tableValues.find((el) => el?._id === 'monday')?.values,
            tuesday: tableValues.find((el) => el?._id === 'tuesday')?.values,
            wednesday: tableValues.find((el) => el?._id === 'wednesday')?.values,
            thursday: tableValues.find((el) => el?._id === 'thursday')?.values,
            friday: tableValues.find((el) => el?._id === 'friday')?.values,
            saturday: tableValues.find((el) => el?._id === 'saturday')?.values,
            sunday: tableValues.find((el) => el?._id === 'sunday')?.values,
        }

        onFormSubmit(values, isSaveForAll);
        setTableValues(staticTableValues);
        setTableSelectAllValues(staticSelectAllValues);
        setIsSaveForAll(false);
    }

    const tableColumns = [
        {
            text: t("irrigationManagement.sector.modal.table.header"),
            dataField: "label",
            headerStyle: {backgroundColor: "white"},
            style: {backgroundColor: "white", paddingBottom: 5, paddingTop: 5},
            formatter: (rowContent, row, index) => {
                return (
                    <div className="d-flex mt-1 justify-content-between">
                        <div>{rowContent}</div>
                        <div className="form-check text-end" style={{fontWeight: 500}}>
                            <label className="form-check-label font-size-10" htmlFor={row?._id}>{t("main.selectAll")}</label>
                            <Input
                                type="checkbox"
                                id={row?._id}
                                name={row?._id}
                                style={{float: "none", marginLeft: "8px"}}
                                className={"form-check-input font-size-12 " + (tableSelectAllValues[row?._id] === null ? "check-all-additional-state" : "")}
                                checked={tableSelectAllValues[row?._id]}
                                onChange={(e) => onChangeSelectAll(e, index)}
                            />
                        </div>
                    </div>
                );
            },
        }
    ];

    const dataColumns = Array.from({length: 24}, (_, index) => index).map((el) => {
        const numberStr = el.toString().padStart(2, '0');
        return {
            text: numberStr,
            dataField: numberStr,
            headerStyle: {backgroundColor: "white"},
            style: {backgroundColor: "white", paddingBottom: 5, paddingTop: 5},
            formatter: (rowContent, row, index) => {
                return (
                    <div className="font-size-12">
                        <Input
                            name={numberStr}
                            type="checkbox"
                            required
                            checked={!!row?.values.some((item) => item === numberStr)}
                            onChange={(e) => onChangeHourlyRanges(e, index, row?._id)}
                        />
                    </div>
                );
            },
        }
    })

    return (
        <Modal isOpen={modalShow} size="xl" className="driver-rule-modal-container">
            <ModalHeader className='driver-rule-modal pe-5 pt-4'>
                <div>{t("irrigationManagement.sector.modal.topic")}</div>
                <CloseButton onClick={toggleModal} variant="white"/>
                <div style={{
                    position: 'absolute',
                    bottom: '-32px',
                    left: '20px',
                    backgroundColor: '#EFF2F7',
                    padding: '13px',
                    borderRadius: '50%',
                    height: '62px',
                    width: '62px',
                    zIndex: 1000
                }}>
                    <Seedling style={{width: "2rem", marginTop: 10}} />
                </div>
            </ModalHeader>

            <ModalBody className="mt-5 overflow-scroll" style={{maxHeight: "60vh"}}>
                <div className='font-size-15 fw-medium'>{t("irrigationManagement.sector.topic")} #0</div>
                <TableFirst
                    dataList={tableValues}
                    columns={[...tableColumns, ...dataColumns]}
                    isStatic={true}
                    containerClass="font-size-11 mt-2 px-0 mb-0 pb-0"
                />

                <div className="form-check ps-0 mt-4" style={{fontWeight: 500}}>
                    <label className="form-check-label fw-normal" htmlFor="save-for-all">
                        {t("irrigationManagement.sector.modal.form.field")}
                    </label>
                    <Input
                        type="checkbox"
                        className="form-check-input"
                        id="save-for-all"
                        name='save-for-all'
                        style={{float: "none", marginLeft: "8px"}}
                        checked={isSaveForAll}
                        onChange={(e) => setIsSaveForAll(e.target.checked)}
                    />
                </div>
            </ModalBody>

            <ModalFooter>
                <div className="text-end form-btn-grp mt-1 mb-1">
                    <Button color="primary" onClick={onSaveClick} className="me-2">{t("main.save")}</Button>
                    <Button color="secondary" onClick={toggleModal}>{t("main.cancel")}</Button>
                </div>
            </ModalFooter>

        </Modal>);
}

export default IrrigationSectorModal