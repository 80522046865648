export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCEED = "GET_DASHBOARD_DATA_SUCCEED";
export const GET_DASHBOARD_DATA_FAILED = "GET_DASHBOARD_DATA_FAILED";

export const GET_DASHBOARD_SINGLE_DATA_SUCCEED = "GET_DASHBOARD_SINGLE_DATA_SUCCEED";
export const GET_DASHBOARD_SINGLE_DATA_FAILED = "GET_DASHBOARD_SINGLE_DATA_FAILED";

export const GET_LAST_NOTIFICATION_LIST_REQUEST = "GET_LAST_NOTIFICATION_LIST_REQUEST";
export const GET_LAST_NOTIFICATION_LIST_SUCCEED = "GET_LAST_NOTIFICATION_LIST_SUCCEED";
export const GET_LAST_NOTIFICATION_LIST_FAILED = "GET_LAST_NOTIFICATION_LIST_FAILED";

export const GET_SYSTEM_HEALTH_DETAILS_REQUEST = "GET_SYSTEM_HEALTH_DETAILS_REQUEST";
export const GET_SYSTEM_HEALTH_DETAILS_SUCCEED = "GET_SYSTEM_HEALTH_DETAILS_SUCCEED";
export const GET_SYSTEM_HEALTH_DETAILS_FAILED = "GET_SYSTEM_HEALTH_DETAILS_FAILED";

export const GET_SHIP_MAP_DETAILS_REQUEST = "GET_SHIP_MAP_DETAILS_REQUEST";
export const GET_SHIP_MAP_DETAILS_SUCCEED = "GET_SHIP_MAP_DETAILS_SUCCEED";
export const GET_SHIP_MAP_DETAILS_FAILED = "GET_SHIP_MAP_DETAILS_FAILED";

export const GET_ONBOARD_DEVICES_DETAILS_REQUEST = "GET_ONBOARD_DEVICES_DETAILS_REQUEST";
export const GET_ONBOARD_DEVICES_DETAILS_SUCCEED = "GET_ONBOARD_DEVICES_DETAILS_SUCCEED";
export const GET_ONBOARD_DEVICES_DETAILS_FAILED = "GET_ONBOARD_DEVICES_DETAILS_FAILED";

export const GET_TEMPERATURE_DETAILS_REQUEST = "GET_TEMPERATURE_DETAILS_REQUEST";
export const GET_TEMPERATURE_DETAILS_SUCCEED = "GET_TEMPERATURE_DETAILS_SUCCEED";
export const GET_TEMPERATURE_DETAILS_FAILED = "GET_TEMPERATURE_DETAILS_FAILED";

export const GET_TORSION_CHART_DATA_REQUEST = "GET_TORSION_CHART_DATA_REQUEST";
export const GET_TORSION_CHART_DATA_SUCCEED = "GET_TORSION_CHART_DATA_SUCCEED";
export const GET_TORSION_CHART_DATA_FAILED = "GET_TORSION_CHART_DATA_FAILED";

export const GET_MAIN_DEVICE_CHART_DATA_REQUEST = "GET_MAIN_DEVICE_CHART_DATA_REQUEST";
export const GET_MAIN_DEVICE_CHART_DATA_SUCCEED = "GET_MAIN_DEVICE_CHART_DATA_SUCCEED";
export const GET_MAIN_DEVICE_CHART_DATA_FAILED = "GET_MAIN_DEVICE_CHART_DATA_FAILED";

export const GET_TEMP_CHART_DATA_REQUEST = "GET_TEMP_CHART_DATA_REQUEST";
export const GET_TEMP_CHART_DATA_SUCCEED = "GET_TEMP_CHART_DATA_SUCCEED";
export const GET_TEMP_CHART_DATA_FAILED = "GET_TEMP_CHART_DATA_FAILED";

export const GET_HUM_CHART_DATA_REQUEST = "GET_HUM_CHART_DATA_REQUEST";
export const GET_HUM_CHART_DATA_SUCCEED = "GET_HUM_CHART_DATA_SUCCEED";
export const GET_HUM_CHART_DATA_FAILED = "GET_HUM_CHART_DATA_FAILED";

export const GET_DASHBOARD_ALERT_DATA_REQUEST = "GET_DASHBOARD_ALERT_DATA_REQUEST";
export const GET_DASHBOARD_ALERT_DATA_SUCCEED = "GET_DASHBOARD_ALERT_DATA_SUCCEED";
export const GET_DASHBOARD_ALERT_DATA_FAILED = "GET_DASHBOARD_ALERT_DATA_FAILED";

export const CREATE_DASHBOARD_ALERT_REQUEST = "CREATE_DASHBOARD_ALERT_REQUEST";
export const CREATE_DASHBOARD_ALERT_SUCCEED = "CREATE_DASHBOARD_ALERT_SUCCEED";
export const CREATE_DASHBOARD_ALERT_FAILED = "CREATE_DASHBOARD_ALERT_FAILED";

export const UPDATE_DASHBOARD_ALERT_REQUEST = "UPDATE_DASHBOARD_ALERT_REQUEST";
export const UPDATE_DASHBOARD_ALERT_SUCCEED = "UPDATE_DASHBOARD_ALERT_SUCCEED";
export const UPDATE_DASHBOARD_ALERT_FAILED = "UPDATE_DASHBOARD_ALERT_FAILED";

export const DELETE_DASHBOARD_ALERT_REQUEST = "DELETE_DASHBOARD_ALERT_REQUEST";
export const DELETE_DASHBOARD_ALERT_SUCCEED = "DELETE_DASHBOARD_ALERT_SUCCEED";
export const DELETE_DASHBOARD_ALERT_FAILED = "DELETE_DASHBOARD_ALERT_FAILED";

export const GET_INDEXES_DATA_REQUEST = "GET_INDEXES_DATA_REQUEST";
export const GET_INDEXES_DATA_SUCCEED = "GET_INDEXES_DATA_SUCCEED";
export const GET_INDEXES_DATA_FAILED = "GET_INDEXES_DATA_FAILED";
