import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CardBody, Card} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import PlantForm from "../../components/forms/PlantForm";

import {
    addSystemPlant,
    getAvailablePlantCategories, getAvailablePlantSpecies, getPlantSpeciesData,
    getSystemPlantData,
    updateSystemPlant
} from "../../redux/actions/systemPlantActions";
import { getAvailableSystemAreas } from "../../redux/actions/systemAreaActions";
import {getSystemData} from "../../redux/actions/systemActions";
import calculateMapCenter from "../../helpers/centerHelper"

const PlantAddEdit = ({isEdit}) => {

    const {t} = useTranslation()
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [formState, setFormState] = useState({
        name: '',
        category: '',
        species: '',
        systemArea: '',
        notes: '',
    })

    const available = useSelector((el) => el.systemData.availableList)
    const systemPlantData = useSelector((el)=> el.systemPlantData.systemPlantData)
    const errorData = useSelector((el) => el.loadingData.error)
    const categories = useSelector((el) => el.systemPlantData.availableCategories)
    const species = useSelector((el) => el.systemPlantData.availableSpecies)
    const systemareas = useSelector((el) => el.systemAreaData.availableSystemAreas)
    const systemData = useSelector((el) => el.systemData.systemData)
    const plantSpeciesData = useSelector((el)=> el.systemPlantData.plantSpeciesData)

    useEffect(() => {
        if(isEdit){
            if(!available.some((el) => el._id === params.systemId)){
                history.push(`/systems`)
            } else {
                dispatch(getSystemPlantData(params.plantId, params.systemId))
            }
        }
        dispatch(getAvailablePlantCategories())
        dispatch(getAvailablePlantSpecies())
        dispatch(getAvailableSystemAreas(params.systemId))
        dispatch(getSystemData(params.systemId))
    }, [])

    useEffect(()=>{
        if(!isEdit && systemData?.metadata?.geoposition?.coordinates && systemData?.metadata?.geoposition?.coordinates.length){
            let polArray = systemData?.metadata?.geoposition?.coordinates[0];
            setFormState({
                ...formState,
                geoLocation: calculateMapCenter(polArray)
            })
        }
    }, [JSON.stringify(systemData)])

    useEffect(() => {
        if (systemPlantData && isEdit) {
            setFormState({
                ...formState,
                _id: systemPlantData._id,
                name: systemPlantData.name,
                notes: systemPlantData.notes,
                systemArea: systemPlantData.systemarea?._id,
                category: systemPlantData.category_id,
                species: systemPlantData.species_id?._id,
            })
        }
    }, [systemPlantData])

    useEffect(() => {
        if (isEdit && formState.species) {
            dispatch(getPlantSpeciesData(formState.species))
        }
    }, [formState.species])

    useEffect(() => {
        if (errorData && errorData.code === 404 && isEdit) {
            history.push(`/system/${params.systemId}/plants`)
        }
    }, [errorData])

    const onSaveClick = (e) => {
        e.preventDefault();

        let data = {};
        data.name = formState.name;
        data.notes = formState.notes;
        data.systemarea = formState.systemArea;
        data.species = formState.species;
        data.category = formState.category;

        if(formState._id){
            data._id = formState._id;
            dispatch(updateSystemPlant(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/plants`)
                }
            })
        } else {
            dispatch(addSystemPlant(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/plants`)
                }
            })
        }

    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={isEdit ? t('plants.editDevice') : t('plants.addDevice')}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/plants`, name: t('plants.breadcrumb1')},
                        {
                            path: isEdit ? `system/${params.systemId}/plant/edit/${params.plantId}` : `system/${params.systemId}/plant/add`,
                            name: t(isEdit ? 'plants.breadcrumbEdit' : 'plants.breadcrumbAdd')
                        }
                    ]}
                />
                <Card>
                    <CardBody>
                        <PlantForm
                            systemPolygon={systemData?.metadata?.geoposition?.coordinates[0]}
                            onSaveClick={onSaveClick}
                            formState={formState}
                            setFormState={setFormState}
                            categories={categories}
                            species={species}
                            systemareas={systemareas}
                            plantSpeciesData={plantSpeciesData}
                            isEdit={isEdit}
                        />
                    </CardBody>
                </Card>
            </div>
        </Layout>
    )
}

export default PlantAddEdit;