import React, {useEffect, useState} from "react";
import {Card, CardBody, Input} from "reactstrap";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {Button, Form, Row} from "react-bootstrap";
import {roleData} from "../../utils/appData";
import Map_with_Polygon from "../../components/common/Map_with_Polygon";

const GardenInfoCard3=()=>{

    const params = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [fileState, setFileState] = useState([])

    const onSaveClick = (e) => {
        e.preventDefault();
    }

    return(
        <Card>
            <CardBody>
                <div>
                    <div className='font-size-15 fw-medium'>{t("gardenInfo.card3.topic")}</div>
                </div>
                <form onSubmit={onSaveClick}>
                    <Row>
                        <div className='mt-3 col-12'>
                            <div>
                                <label className='mb-2 p-0'>{t('gardenInfo.card3.form1.name')}</label>
                                <div className='text-semi-dark mb-2 p-0'>{t('gardenInfo.card3.form1.description')}</div>
                            </div>
                        </div>
                    </Row>

                    <div className='col-12 text-end mt-3 form-btn-grp'>
                        <Button color='primary' type='submit' className='me-2'>{t('gardenInfo.card3.button')}</Button>
                    </div>

                </form>
            </CardBody>
        </Card>
    )
}

export default GardenInfoCard3;