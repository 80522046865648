import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useParams} from "react-router-dom";
import {Button, Card, CardBody, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import Plant from "../../components/formViews/Plant";

import RegisterEntryListSub from "../OrchardEntries/RegisterEntryListSub";

import {getAvailablePlantCategories, getSystemPlantData} from "../../redux/actions/systemPlantActions";
import {getSystemData} from "../../redux/actions/systemActions";


const PlantView = () => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const available = useSelector((el) => el.systemData.availableList)
    const systemPlantData = useSelector((el)=> el.systemPlantData.systemPlantData)
    const errorData = useSelector((el) => el.loadingData.error)
    const role = useSelector((state) => state.authData?.userDetails?.role)
    const categories = useSelector((el) => el.systemPlantData.availableCategories)

    const [formState, setFormState] = useState({
        name: '',
        category: '',
        species: '',
        systemArea: '',
        notes: '',
    })

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            history.push(`/systems`)
        } else {
            dispatch(getSystemPlantData(params.plantId, params.systemId))
            dispatch(getSystemData(params.systemId))
            dispatch(getAvailablePlantCategories())
        }
    }, [])

    useEffect(() => {
        if (systemPlantData) {
            setFormState({
                name: systemPlantData.name,
                category: systemPlantData.category_id,
                species: systemPlantData.species,
                systemArea: systemPlantData.systemarea?.name,
                notes: systemPlantData.notes,
            })
        }
    }, [systemPlantData])

    useEffect(() => {
        if (errorData && errorData.code === 404 ) {
            history.push(`/system/${params.systemId}/plants`)
        }
    }, [errorData])


    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t(formState.name)}
                    breadcrumbItems={[
                        {path: `system/view/${params.systemId}`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/plants`, name: t('plants.breadcrumb1')},
                        {path: `system/${params.systemId}/plant/view/${params.plantId}`, name: t(formState.name)},
                    ]}
                />

                <Card>
                    <CardBody>
                        <div className='row'>
                            <div className={role === 'customer' || role === 'guest' ? 'col-12' : 'col-md-6'}>
                                <Plant
                                    formState={formState}
                                    categoryName={categories.find((el) => el._id === formState.category)?.name}
                                />
                                <div className='col-12 pb-5 text-end mt-3 form-btn-grp'>
                                    <Link to={`/system/${params.systemId}/plants`}><Button type='button' className='btn-secondary'>{t('main.back')}</Button></Link>
                                </div>
                            </div>
                            {(role === 'admin' || role.includes('supervisor')) &&
                                <div className='col-md-6'>
                                    <RegisterEntryListSub />
                                </div>
                            }
                        </div>
                    </CardBody>
                </Card>

            </div>
        </Layout>
    )
}

export default PlantView;