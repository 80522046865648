import {orchardRegisterActionTypes} from "../actionTypes/index";
import produce from "immer";

const initialState = {
    interventionTypeList: [],
    registerEntryCategoriesList: [],
    registerEntriesList:[],
    registerEntryData: null,
    registerEntryImageData: [],
    totalSize:0,
    plantRegisterEntriesList:[],
    plantTotalSize:0,
};

const orchardRegisterData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case orchardRegisterActionTypes.GET_REGISTER_ENTRIES_CATEGORIES_LIST_SUCCEED: {
                state.registerEntryCategoriesList = payload;
                return state;
            }
            case orchardRegisterActionTypes.GET_INTERVENTION_TYPES_LIST_SUCCEED: {
                state.interventionTypeList = payload;
                return state;
            }
            case orchardRegisterActionTypes.GET_REGISTER_ENTRIES_LIST_SUCCEED: {
                state.registerEntriesList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case orchardRegisterActionTypes.GET_PLANT_REGISTER_ENTRIES_LIST_SUCCEED: {
                state.plantRegisterEntriesList = payload.results;
                state.plantTotalSize = payload.totalResults;
                return state;
            }
            case orchardRegisterActionTypes.GET_REGISTER_ENTRY_DATA_SUCCEED: {
                state.registerEntryData = payload;
                return state;
            }
            case orchardRegisterActionTypes.GET_REGISTER_ENTRY_IMAGE_DATA_SUCCEED: {
                state.registerEntryImageData = payload;
                return state;
            }
            case orchardRegisterActionTypes.DELETE_REGISTER_ENTRY_SUCCEED: {
                const deletedId = state.registerEntriesList.findIndex((item)=>item._id===payload._id);
                state.registerEntriesList.splice(deletedId,1);
                state.totalSize--;
                return state;
            }

            case orchardRegisterActionTypes.GET_REGISTER_ENTRY_IMAGE_DATA_REQUEST: {
                state.registerEntryImageData = [];
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default orchardRegisterData;
