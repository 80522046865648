import React, {useState, useEffect} from "react";
import {Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useParams, Link} from "react-router-dom";
import moment from "moment";

import TableFirst from "../../components/tables/TableFirst";

import {ReactComponent as Book} from '../../assets/icons/book-open.svg'
import {getPlantRegisterEntriesList} from "../../redux/actions/orchardRegisterActions";
import {useDispatch, useSelector} from "react-redux";

const RegisterEntryListSub = () => {

    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();

    const orchardRegisterData = useSelector((el)=> el.orchardRegisterData)

    useEffect(() => {
        dispatch(getPlantRegisterEntriesList(params.systemId, params.plantId, 1 ))
    }, [])

    const getDataList=(page)=>{
        dispatch(getPlantRegisterEntriesList(params.systemId, params.plantId, page, ))
    }

    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex p-2 ps-3">
                        <div className="me-3 d-flex align-items-center">
                            <Book className='pe-4 tap-icon'/>
                        </div>
                        <div className="row-details">
                            <div className='text-new-dark fw-bold font-size-13'>{row.title}</div>
                            <div>
                                <div className='fw-light text-semi-dark font-size-12'>{t("main.date")}: {moment(row.date).format('YYYY-MM-DD, HH:mm')}</div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            key: "edit-delete-save",
            text: "",
            dataField: "",
            style: {textAlign: "right"},
            headerStyle: {backgroundColor: "white", boxShadow: "0"},
            formatter: (rowContent, row) => {
                return (
                    <div className="text pe-3">
                        <Link to={`/system/${params.systemId}/register-entries/category/${row?.entry_category_type}/entry/view/${row._id}/?from-plants=${params.plantId}`}><Button color="primary">{t("main.view")}</Button></Link>
                    </div>
                );
            },
        },
    ];

    return (
        <div className='mt-2'>

            <div className='d-flex mb-4'>
                <div className='font-size-15 fw-medium mb-3'>{t("registerEntry.subSection.title")}</div>
            </div>
            <TableFirst
                dataList={orchardRegisterData.plantRegisterEntriesList}
                columns={tableColumns}
                total={orchardRegisterData.plantTotalSize}
                getDataList={getDataList}
                containerClass={'header-less-table-container orchard-sub-table-container'}
                rowBorder={true}
            />
        </div>
    );
}

export default RegisterEntryListSub;
