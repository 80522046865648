import React, {useState} from "react";
import {Form} from "react-bootstrap";
import {Button, Input, Row} from "reactstrap";
import {useTranslation} from "react-i18next";


const TimeRangeSelect = (props) => {

    const {t} = useTranslation();

    const [checkedValue, setCheckedValue] = useState('lastyear');
    const [dates, setDates] = useState({
        fromDate: '',
        toDate: ''
    })

    const onChangeHandler = (e) => {
        const {name, value, type} = e.target;

        if (type === 'date') {
            setDates({
                ...dates,
                [name]: value
            })
        } else {
            setCheckedValue(value)
        }
    }

    const onApplyFilters=()=>{
        props.onApplyFilters(checkedValue, dates)
    }

    return (
        <div style={{width: '100%'}} className='d-flex flex-column flex-md-row ps-4 pe-3 justify-content-between align-items-end'>

            <div>
                <Form>
                    <div className='row radio-container'>
                        <Form.Check
                            type='radio'
                            value='today'
                            id='today'
                            name='time-range'
                            label={t("timerange.form.today")}
                            onChange={onChangeHandler}
                            checked={checkedValue === 'today'}
                        />
                        <Form.Check
                            type='radio'
                            value='lastsixmonth'
                            id='lastsixmonth'
                            name='time-range'
                            label={t("timerange.form.lastsixmonth")}
                            onChange={onChangeHandler}
                            checked={checkedValue === 'lastsixmonth'}
                        />
                    </div>
                    <div className='row radio-container mt-1'>
                        <Form.Check
                            type='radio'
                            value='lastweek'
                            id='lastweek'
                            name='time-range'
                            label={t("timerange.form.lastweek")}
                            onChange={onChangeHandler}
                            checked={checkedValue === 'lastweek'}
                        />
                        <Form.Check
                            type='radio'
                            value='lastyear'
                            id='lastyear'
                            name='time-range'
                            label={t("timerange.form.lastyear")}
                            onChange={onChangeHandler}
                            checked={checkedValue === 'lastyear'}
                        />
                    </div>
                    <div className='d-flex row radio-container mt-1'>
                        <Form.Check
                            type='radio'
                            value='lastmonth'
                            id='lastmonth'
                            name='time-range'
                            label={t("timerange.form.lastmonth")}
                            onChange={onChangeHandler}
                            checked={checkedValue === 'lastmonth'}
                        />
                        <Form.Check
                            type='radio'
                            value='custom'
                            id='custom'
                            name='time-range'
                            label={t("timerange.form.customrange")}
                            onChange={onChangeHandler}
                            checked={checkedValue === 'custom'}
                        />
                        <div className='d-flex align-items-center p-0 mt-2 mt-md-0'>
                            <Input
                                type='date'
                                name='fromDate'
                                id='fromDate'
                                disabled={checkedValue !== 'custom'}
                                onChange={onChangeHandler}
                                value={dates.fromDate}
                            />
                            <div style={checkedValue !== 'custom' ? {background: '#eff2f7'} : {}}>:</div>
                            <Input
                                type='date'
                                name='toDate'
                                id='toDate'
                                disabled={checkedValue !== 'custom'}
                                onChange={onChangeHandler}
                                value={dates.toDate}
                                min={dates.fromDate}
                            />
                        </div>
                    </div>

                </Form>
            </div>
            <div><Button disabled={checkedValue === 'custom' && (!dates.fromDate || !dates.toDate)} className='mt-4 mt-md-0' onClick={onApplyFilters} color='primary'>{t("main.applyFilters")}</Button></div>
        </div>
    )
}

export default TimeRangeSelect;