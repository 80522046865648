import React, {useState, useEffect} from "react";
import {Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useParams, Link} from "react-router-dom";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as Seedling} from '../../assets/icons/seedling.svg'

import TableFirst from "../../components/tables/TableFirst";
import SearchBar from "../../components/others/SearchBar";

import {
    addPlantCategory, addPlantCategorySpecies,
    getPlantCategorySpeciesList,
    updatePlantCategory, updatePlantCategorySpecies
} from "../../redux/actions/plantCategoryActions";
import PlantSpeciesModal from "../../components/modals/PlantSpeciesModal";

const PlantSpeciesList = ({isView}) => {

    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();

    const initialData = {
        common_name: "",
        scientific_name: "",
        min_vegetation_temp: "",
        max_vegetation_temp: "",
        ec_max: "",
        ph_min: "",
        ph_max: "",
        resistance_to_frost: "yes",
        co2: "",
        pm10: "",
        pm2_5: "",
        no2: "",
        so2: "",
        o3: "",
        category_id: ""
    };

    const plantCategorySpeciesData = useSelector((el)=> el.plantCategoryData)

    const [plantCategorySpeciesSearch, setPlantCategorySpeciesSearch] = useState("");
    const [speciesModalShow, setSpeciesModalShow] = useState("");
    const [formState, setFormState] = useState(initialData);

    useEffect(() => {
        if(params.plantCategoryId) {
            dispatch(getPlantCategorySpeciesList(params.plantCategoryId, 1 , null))
        }
    }, [])

    const onAddModalClick= () => {
        setFormState(initialData)
        setSpeciesModalShow("new");
    }

    const getDataList=(page)=>{
        dispatch(getPlantCategorySpeciesList(params.plantCategoryId, page, plantCategorySpeciesSearch ? plantCategorySpeciesSearch : null ))
    }

    const onPlantCategorySpeciesSearch=()=>{
        dispatch(getPlantCategorySpeciesList(params.plantCategoryId,1, plantCategorySpeciesSearch))
    }

    const onSpeciesClick = (data, isView) => {
        setFormState({
            ...initialData,
            ...data,
            resistance_to_frost: data?.resistance_to_frost ? "yes" : "no"
        })
        setSpeciesModalShow(isView ? "view" : "edit")
    }

    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "common_name",
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex">
                        <div className="me-3 d-flex align-items-center">
                            <Seedling className='pe-4 tap-icon'/>
                        </div>
                        <div>
                            <div className='text-new-dark fw-bold font-size-13'>{rowContent}</div>
                            <div className='fw-light text-semi-dark font-size-12 d-flex align-items-center'>{t("main.date")}: {moment(row.date).format('YYYY-MM-DD, HH:mm')}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            key: "edit-delete-save",
            text: "",
            dataField: "",
            style: {textAlign: "right"},
            headerStyle: {backgroundColor: "white", boxShadow: "0"},
            formatter: (rowContent, row) => {
                return (
                    <div className="text">
                        <Button color="primary" onClick={() => onSpeciesClick(row, true)}>{t("main.view")}</Button>
                        {!isView ? <Button color="warning" onClick={() => onSpeciesClick(row, false)} className="ms-2">{t("main.edit")}</Button> :""}
                    </div>
                );
            },
        },
    ];

    const onFormSubmit = (e) => {
        e.preventDefault();

        setSpeciesModalShow("");

        let data = {};

        data.common_name = formState.common_name;
        data.scientific_name = formState.scientific_name;
        data.min_vegetation_temp = formState.min_vegetation_temp;
        data.max_vegetation_temp = formState.max_vegetation_temp;
        data.ec_max = formState.ec_max;
        data.ph_min = formState.ph_min;
        data.ph_max = formState.ph_max;
        data.resistance_to_frost = formState.resistance_to_frost === "yes";
        data.co2 = formState.co2;
        data.pm10 = formState.pm10;
        data.pm2_5 = formState.pm2_5;
        data.no2 = formState.no2;
        data.so2 = formState.so2;
        data.o3 = formState.o3;


        if(formState._id){
            data._id = formState._id;
            dispatch(updatePlantCategorySpecies(data)).then((res)=>{
                if(res){
                    setFormState(initialData)
                    getDataList(1)
                }
            })
        } else {
            data.category_id = params.plantCategoryId;
            dispatch(addPlantCategorySpecies(data)).then((res)=>{
                if(res){
                    getDataList(1)
                }
            })
        }
    }

    return (
        <div className='mt-2'>

            <div className='d-flex mb-4'>
                <div className='font-size-15 fw-medium mb-3'>{t("plantSpecies.topic")}</div>
            </div>
            {params.plantCategoryId ?
                <>
                    <div>
                        <SearchBar
                            name="plantSpecies"
                            btnName={t("plantSpecies.add")}
                            link={`plant-categories`}
                            searchValue={plantCategorySpeciesSearch}
                            setSearchValue={setPlantCategorySpeciesSearch}
                            onSearch={onPlantCategorySpeciesSearch}
                            className="px-0"
                            isAddModal={true}
                            setModal={onAddModalClick}
                            addDisabled={isView}
                        />
                    </div>
                    <TableFirst
                        dataList={plantCategorySpeciesData.plantCategorySpeciesList}
                        columns={tableColumns}
                        total={plantCategorySpeciesData.speciesTotalSize}
                        getDataList={getDataList}
                        containerClass={'header-less-table-container orchard-sub-table-container'}
                        rowBorder={true}
                    />
                </>
                :
                <div className="rounded p-3" style={{color: "#2C3978", backgroundColor: "#DDE2FA"}}>{t("plantSpecies.alert")}</div>
            }

            <PlantSpeciesModal
                formState={formState}
                setFormState={setFormState}
                modalShow={!!speciesModalShow}
                setModalShow={setSpeciesModalShow}
                onFormSubmit={onFormSubmit}
                isView={speciesModalShow === "view"}
                isEdit={speciesModalShow === "edit"}
            />
        </div>
    );
}

export default PlantSpeciesList;
