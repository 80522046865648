import React, {useEffect, useState} from "react";
import Switch from "react-switch";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Card, CardBody} from "reactstrap";
import Select from "react-select";
import { saveAs } from 'file-saver';
import moment from "moment/moment";

import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import SearchSelect from "../../components/common/SearchSelect";
import IrrigationSectorCard from "../../components/others/IrrigationSectorCard";
import GraphModal from "../../components/modals/GraphModal";
import IrrigationSectorModal from "../../components/modals/IrrigationSectorModal";

import {
    getAvailableDeviceTypesOptions,
    getAvailableSystemUserOptions, getIrrigationManagementData, updateIrrigationManagementData
} from "../../redux/actions/irrigationManagementActions";

import {getRuleEntityChartData} from "../../redux/actions/ruleEntityActions";

import getGraphType from "../../helpers/ruleEntityGraphTypeHelper";
import getGraphOptions from "../../helpers/ruleEntityGraphOptionsHelper";

import request from "../../helpers/requestHelper";

import { ReactComponent as CircleIcon } from '../../assets/icons/circleloaderfive.svg'


const customStyles = {

    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#eff2f7' : '',
        minHeight: '37px',
        maxHeight: '37px',
        boxShadow: 'none',
        border: '1px solid #ced4da',
        '&:active': {
            borderColor: "#b9bfc4 !important",
            outline: 0
        },
        '&:focus': {
            borderColor: "#b9bfc4 !important",
            outline: 0
        },
        '&:hover': {
            borderColor: "#b9bfc4 !important",
            outline: 0
        },

    }),

    multiValue: (provided) => ({
        ...provided,
        border: '#D5D5D5 solid 0.5px',
        borderRadius: '4px',
        backgroundColor: '#F8F9FA'
    }),

    multiValueLabel: (provided) => ({
        ...provided,
        color: '#74788D'
    }),

    multiValueRemove: (provided, state) => ({
        ...provided,
        color: '#74788D',
        width: state.isDisabled ? '0px' : '',
        '&:hover': {backgroundColor: '#F8F9FA', color: '#74788D'}

    }),
}


const IrrigationManagement=()=>{

    const params = useParams();
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const available = useSelector((el) => el.systemData.availableList)
    const isFetching = useSelector((el)=> el.dashboardData.isFetching)
    const errorData = useSelector((el) => el.loadingData.error)
    const role = useSelector((state) => state.authData?.userDetails?.role)
    const switchLDeviceListData = useSelector((el) => el.irrigationManagementData.switchDeviceOptions)
    const moistureDeviceListData = useSelector((el) => el.irrigationManagementData.moistureDeviceOptions)
    const systemUserListData = useSelector((el) => el.irrigationManagementData.systemUserOptions)
    const irrigationManagementData = useSelector((el) => el.irrigationManagementData.irrigationManagementData)
    const ruleEntityData = useSelector((el)=> el.ruleEntityData)

    const [formState, setFormState] = useState({
        active_irrigation_management: false,
        inibition_device_slug_id: "",
        notification_user_emails: [],
        inibition_device: false
    });
    const [sectorFormData, setSectorFormData] = useState([]);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const [isHourRangesOpen, setIsHourRangesOpen] = useState(false)
    const [alert, setAlert] = useState('')

    useEffect(() => {
        if(irrigationManagementData) {
            setFormState({
                active_irrigation_management: irrigationManagementData.active_irrigation_management,
                inibition_device_slug_id: irrigationManagementData.inibition_device_slug_id,
                notification_user_emails: irrigationManagementData?.notification_users?.map((el) => {
                    return {
                        value: el.email,
                        label: el.fullname
                    }
                }),
                inibition_device: irrigationManagementData?.inibition_device?.state?.value === "on"
            })
            setSectorFormData(irrigationManagementData?.sectors ?? [])
        }
    }, [JSON.stringify(irrigationManagementData)]);

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            if(role === 'customer' || role === 'guest'){
                history.push(`/system/${available[0]._id}/dashboard`)
            } else {
                history.push(`/systems`)
            }
        } else {
            dispatch(getAvailableDeviceTypesOptions(params.systemId, true))
            dispatch(getAvailableDeviceTypesOptions(params.systemId, false))
            dispatch(getAvailableSystemUserOptions(params.systemId))
            dispatch(getIrrigationManagementData(params.systemId))
        }
    }, [location.pathname])

    useEffect(() => {
        if (errorData && errorData.code === 404 && !isFetching) {
            if(role === 'customer' || role === 'guest'){
                history.push(`/system/${available[0]._id}/dashboard`)
            } else {
                history.push(`/systems`)
            }
        }
    }, [errorData])

    const onAddSectorClick = () => {
        const dataObj = {
            name: "",
            minv_sm: "",
            maxv_sm: "",
            valve_device_slug_id: "",
            irrigation_threshold_value: "",
            sm_devices: [],
            minutes_irrigation_time: "",
            minutes_stop_time: "",
            max_irrigation_cycles: "",
            stop_irrigations_hour_ranges: null
        }

        const dataArr = JSON.parse(JSON.stringify(sectorFormData));

        dataArr.push(dataObj);
        setSectorFormData(dataArr);
    }

    const onDeleteSectorClick = (id) => {
        const dataArr = JSON.parse(JSON.stringify(sectorFormData));

        dataArr.splice(id, 1);
        setSectorFormData(dataArr);
    }

    const onResetClick = (id) => {
        const dataArr = JSON.parse(JSON.stringify(sectorFormData));

        dataArr[id] = {
            name: "",
            minv_sm: "",
            maxv_sm: "",
            valve_device_slug_id: "",
            irrigation_threshold_value: "",
            sm_devices: [],
            minutes_irrigation_time: "",
            minutes_stop_time: "",
            max_irrigation_cycles: "",
            stop_irrigations_hour_ranges: null
        };
        setSectorFormData(dataArr);
    }

    const onChangeHandlerSection = (e, index) => {
        const dataArr = JSON.parse(JSON.stringify(sectorFormData));

        const {name, value} = e.target;
        if(name === "name" && value.length < 51) {
            dataArr[index][name] = value;
        } else {
            dataArr[index][name] = value;
        }

        setSectorFormData(dataArr);
    }

    const validateDetails = () => {
        let isValidated = true;
        for (let i=0; i<sectorFormData.length; i++) {
            const dataItem = sectorFormData[i];

            if(!dataItem.name || !dataItem.valve_device_slug_id) {
                isValidated = false;
                return false;
            } else if((!dataItem.minv_sm && dataItem.minv_sm !== 0) || (!dataItem.maxv_sm && dataItem.maxv_sm !== 0) || (!dataItem.irrigation_threshold_value && dataItem.irrigation_threshold_value !== 0)) {
                isValidated = false;
                return false;
            } else if(!dataItem?.sm_devices || !dataItem?.sm_devices?.length) {
                isValidated = false;
                return false;
            } else if((!dataItem.minutes_irrigation_time && dataItem.minutes_irrigation_time !== 0) || (!dataItem.minutes_stop_time && dataItem.minutes_stop_time !== 0) || (!dataItem.max_irrigation_cycles && dataItem.max_irrigation_cycles !== 0)) {
                isValidated = false;
                return false;
            }
        }

        return isValidated;
    }

    const onSaveClick = () => {
        const isValidated = validateDetails();
        if(isValidated) {
            setAlert("")
            dispatch(updateIrrigationManagementData(formState, sectorFormData, params.systemId))
        } else {
            setAlert(t("irrigationManagement.alert"))
            setTimeout(() => {
                setAlert('')
            }, 10000)
        }
    }

    const openHistory = (item) => {
        dispatch(getRuleEntityChartData(item._id, params.systemId, 'lastyear', null, null))
        setSelectedData(item)
        setIsHistoryOpen(true)
    }

    const getGraphData=(value, from, to)=>{
        if(value === 'custom') {
            dispatch(getRuleEntityChartData(selectedData._id, params.systemId, null, from, to))
        } else {
            dispatch(getRuleEntityChartData(selectedData._id, params.systemId, value, null, null))
        }
    }

    const onExportData= async (value, from, to)=>{
        let filter, start, end;
        if(value === 'custom') {
            start = from;
            end = to;
        } else {
            filter = value;
        }

        const res = await request({
            url: `rulesystem/ui/ruleentities/${selectedData._id}/export?system=${params.systemId}${filter ? '&dateFilter=' +filter : ''}${!filter ? '&startDate='+start : ''}${!filter ? '&endDate='+end : ''}`,
            auth: true,
            method: 'GET',
            responseType: 'arraybuffer'
        });
        const blob = new Blob([res], {
            type: 'text.csv',
        });
        let name = selectedData._id + '_' +(filter ? filter : start+ '_' +end) + '.csv'
        saveAs(blob, name)
    }

    const onHourRangesClick = (item, index) => {
        setSelectedData({...item, index})
        setIsHourRangesOpen(true)
    }

    const onHourRangesSave = (values, isSaveForAll) => {
        setIsHourRangesOpen(false);
        const dataArr = JSON.parse(JSON.stringify(sectorFormData ?? []));

        if(isSaveForAll) {
            for(let i=0; i<dataArr.length; i++) {
                dataArr[i].stop_irrigations_hour_ranges = values;
            }
        } else {
            dataArr[selectedData?.index].stop_irrigations_hour_ranges = values;
        }

        setSectorFormData(dataArr);
    }

    return(
        <Layout>
            {alert && <Alert style={{position: 'fixed', width: '100%', top: 0, left: 0, zIndex: 1000, textAlign: 'right'}} color='danger'>{alert}</Alert>}

            <Breadcrumb
                title={t('irrigationManagement.title')}
                systemData={{id: params.systemId, name: available.find((el) => el._id === params.systemId)?.name}}
                breadcrumbItems={[
                    {
                        path: `system/${params.systemId}/garden-info`,
                        name: t(available.find((el) => el._id === params.systemId)?.name)
                    },
                    {path: `system/${params.systemId}/garden-info`, name: t('irrigationManagement.breadcrumb')}
                ]}
            />

            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between">
                        <div>
                            <div
                                className='font-size-15 fw-medium'>{available.find((el) => el._id === params.systemId)?.name}</div>
                            <div
                                className='text-semi-dark mt-2 mb-4'>{t("irrigationManagement.card1.description")}</div>
                        </div>
                        <div className='d-flex flex-row'>
                            <div style={{fontWeight: '500'}}
                                 className='text-nowrap me-4 font-size-13 mt-1'>{t("irrigationManagement.card1.form1.name")}</div>
                            <Switch
                                onChange={(val) => setFormState({...formState, active_irrigation_management: val})}
                                checked={formState.active_irrigation_management}
                                checkedIcon={<span style={{color: '#fff', marginLeft: 5}}></span>}
                                uncheckedIcon={false}
                                onHandleColor="#fff"
                                offHandleColor='#ccc'
                                onColor='#556EE6'
                                offColor='#fff'
                                handleDiameter={20}
                                boxShadow="0px rgba(0, 0, 0, 0)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0)"
                            />
                        </div>
                    </div>

                    <div className="mt-3 row">
                        <div className='col-md-3 mt-2 mt-md-0'>
                            <div style={{fontWeight: '500'}}
                                 className='text-nowrap me-4 mb-2'>{t("irrigationManagement.card1.form2.name")}</div>
                            <Switch
                                onChange={(val) => setFormState({...formState, inibition_device: val})}
                                checked={formState.inibition_device}
                                checkedIcon={<span style={{color: '#fff', marginLeft: 5}}></span>}
                                uncheckedIcon={false}
                                onHandleColor="#fff"
                                offHandleColor='#ccc'
                                onColor='#556EE6'
                                offColor='#fff'
                                handleDiameter={20}
                                boxShadow="0px rgba(0, 0, 0, 0)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0)"
                                disabled
                            />
                        </div>

                        <div className="col-md-3 mt-2 mt-md-0">
                            <div><label className="mb-2 p-0"
                                        htmlFor="switchDevice">{t("irrigationManagement.card1.form3.name")}</label>
                            </div>
                            <SearchSelect
                                name="switchDevice"
                                data={switchLDeviceListData.map((item) => {
                                    return {
                                        value: item.slug_id,
                                        label: item.name,
                                    };
                                })}
                                value={
                                    formState?.inibition_device_slug_id
                                        ? {
                                            value: formState?.inibition_device_slug_id,
                                            label: switchLDeviceListData.find(
                                                (item) => item.slug_id === formState?.inibition_device_slug_id
                                            )?.name,
                                        }
                                        : null
                                }
                                onChangeHandler={(e) => setFormState({
                                    ...formState,
                                    inibition_device_slug_id: e ? e.value : ''
                                })}
                            />
                        </div>

                        <div className="mt-2 mt-md-0 col-md-4">
                            <div><label className="mb-2 p-0"
                                        htmlFor="systemUsers">{t("irrigationManagement.card1.form4.name")}</label></div>
                            <div>
                                <Select
                                    isMulti
                                    name="systemUsers"
                                    options={systemUserListData.map((el) => {
                                        return {
                                            label: el.fullname,
                                            value: el.email
                                        }
                                    })}
                                    isOptionDisabled={() => formState?.notification_user_emails?.length >= 20}
                                    className="basic-multi-select"
                                    components={{IndicatorSeparator: () => null}}
                                    isClearable={false}
                                    onChange={(e) => {
                                        setFormState({...formState, notification_user_emails: e})
                                    }}
                                    value={formState?.notification_user_emails ?? []}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                        <div className="ms-auto mt-3 mt-md-0 col-md-2 d-md-flex align-items-end">
                            <Button color="primary" onClick={onSaveClick}
                                    className="ms-auto">{t("main.saveEverything")}</Button>
                        </div>

                    </div>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='font-size-15 fw-medium'>{t("irrigationManagement.card2.topic")}</div>
                        <Button onClick={onAddSectorClick}
                                color='success'>{t('irrigationManagement.card2.button')}</Button>
                    </div>
                </CardBody>
            </Card>

            {sectorFormData?.map((item, index) => {

                return (
                    <IrrigationSectorCard
                        key={"irrigation-sector-" + index}
                        customStyles={customStyles}
                        index={index}
                        item={item}
                        switchDeviceListData={switchLDeviceListData}
                        moistureDeviceListData={moistureDeviceListData}
                        onChangeHandlerSection={onChangeHandlerSection}
                        onDeleteSectorClick={onDeleteSectorClick}
                        onResetClick={onResetClick}
                        onChartClick={openHistory}
                        onHourRangesClick={(data) => onHourRangesClick(data, index)}
                    />
                )
            })}

            <div className="ms-auto mt-4 text-center">
                <Button onClick={onAddSectorClick} color='success'>{t('irrigationManagement.card2.button')}</Button>
            </div>

            <div className="ms-auto mt-4 text-center" style={{paddingBottom: 80}}>
                <Button color="primary" onClick={onSaveClick}>{t("main.saveEverything")}</Button>
            </div>

            <GraphModal
                isOpen={isHistoryOpen}
                setIsOpen={setIsHistoryOpen}
                title={t("ruleEntities.history.topic")}
                graphType={getGraphType(selectedData.type)}
                graphOptions={getGraphOptions(selectedData.type, selectedData.state?.attributes, t)}
                graphData={{
                    name: "Current",
                    data: ruleEntityData.ruleEntityChart.map((el) => {
                        return [
                            moment(el.time).valueOf(),
                            el.value
                        ]
                    })
                }}
                getGraphData={getGraphData}
                onExport={onExportData}
                data={
                    <div className='data-row-1 mt-3'>
                        <div className='col-9'>
                            <div className='pe-5'>
                                <CircleIcon/>
                            </div>
                            <div>
                                <div style={{fontWeight: 600}}> {selectedData.name}</div>
                                <div> {t('main.lastStateUpdate')}: {selectedData.state?.time ? moment(selectedData.state?.time).format('DD/MM/YYYY HH:mm') : "-"}</div>
                            </div>
                        </div>
                        <div className='col-3 text-end'>{selectedData.state?.value}{selectedData.uom}</div>
                    </div>
                }
            />

            <IrrigationSectorModal
                modalShow={isHourRangesOpen}
                setModalShow={setIsHourRangesOpen}
                sectorData={selectedData.stop_irrigations_hour_ranges}
                onFormSubmit={onHourRangesSave}
            />
        </Layout>
    )
}

export default IrrigationManagement;