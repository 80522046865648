import { combineReducers } from 'redux';

import authData from "./authReducer";
import systemData from "./systemReducer";
import systemAreaData from "./systemAreaReducer";
import systemPlantData from "./systemPlantReducer";
import userData from "./userReducer";
import loadingData from "./loadingReducer";
import orchardRegisterData from "./orchardRegisterReducer";
import driverData from "./driverReducer";
import ruleData from "./ruleReducer";
import notificationData from "./notificationReducer";
import ruleEntityData from "./ruleEntityReducer";
import dashboardData from "./dashboardReducer";
import deviceData from "./deviceReducer";
import plantCategoryData from "./plantCategoryReducer";
import gardenInfoData from "./garedenInfoReducer";
import irrigationManagementData from "./irrigationManagementReducer";

const rootReducer = combineReducers({
    loadingData,
    authData,
    systemData,
    systemAreaData,
    userData,
    systemPlantData,
    orchardRegisterData,
    driverData,
    ruleData,
    notificationData,
    ruleEntityData,
    dashboardData,
    deviceData,
    plantCategoryData,
    gardenInfoData,
    irrigationManagementData
});

export default rootReducer;
