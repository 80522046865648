import React, {useEffect, useRef, useState} from "react";
import Layout from "../../layout/layout";
import {config} from "../../config";
import DashboardPLT from "./DashboardPLT";
import DashboardVLM from "./DashboardVLM";
import Breadcrumb from "../../components/common/Breadcrumb";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    getDashboardDataPLT,
    getDashboardDataVLM,
    getHumChartData, getIndexesData, getLastNotificationData, getSystemHealthData,
    getTempChartData
} from "../../redux/actions/dashboardActions";
import moment from "moment";
import {lastNotificationsTimeOut, systemHealthTimeOut} from "../../utils/timeoutData";

import useInterval from "../../helpers/timeoutHelper"

const Dashboard=()=>{

    const TARGET_CODE = config.TARGET_CODE;
    const params = useParams();
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const available = useSelector((el) => el.systemData.availableList)
    const dashboardData = useSelector((el)=> TARGET_CODE === 'PLT' ? el.dashboardData.dashboardDataPLT : el.dashboardData.dashboardDataVLM)
    const areaData = useSelector((el)=> el.dashboardData.areasData)
    const onboardDevicesData = useSelector((el)=> el.dashboardData.onboardDevicesData)
    const dashboardAlertData = useSelector((el)=> el.dashboardData.dashboardAlertData)
    const isFetching = useSelector((el)=> el.dashboardData.isFetching)
    const errorData = useSelector((el) => el.loadingData.error)
    const role = useSelector((state) => state.authData?.userDetails?.role)
    const isLoading = useSelector((el)=> el.loadingData.tableLoading)
    const loading = useSelector((state) => state.loadingData.loading)

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            if(role === 'customer' || role === 'guest'){
                history.push(`/system/${available[0]._id}/dashboard`)
            } else {
                history.push(`/systems`)
            }
        } else {
            if(TARGET_CODE === 'PLT'){
                dispatch(getDashboardDataPLT(params.systemId))
                dispatch(getIndexesData(params.systemId))
            } else {
                dispatch(getDashboardDataVLM(params.systemId))
            }
        }
    }, [location.pathname])

    const getGraphData=(id, value, areaId)=>{
        if(id === 0){
            dispatch(getHumChartData(areaId, params.systemId, value))
        } else {
            dispatch(getTempChartData(areaId, params.systemId, value))
        }
    }

    useEffect(() => {
        if (errorData && errorData.code === 404 && !isFetching) {
            if(role === 'customer' || role === 'guest'){
                history.push(`/system/${available[0]._id}/dashboard`)
            } else {
                history.push(`/systems`)
            }
        }
    }, [errorData])

    useInterval(() => {
        if(available.some((el) => el._id === params.systemId)) {
            if(!loading) dispatch(getSystemHealthData(params.systemId));
        }
    }, systemHealthTimeOut);

    useInterval(() => {
        if(available.some((el) => el._id === params.systemId)) {
            if(!loading) dispatch(getLastNotificationData(params.systemId));
        }
    }, lastNotificationsTimeOut);

    return(
        <Layout>
            <Breadcrumb
                title={t('dashboard.title')}
                systemData={{id: params.systemId, name: available.find((el) => el._id === params.systemId)?.name}}
                breadcrumbItems={[
                    {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                    {path: `system/${params.systemId}/dashboard`, name: t('dashboard.breadcrumb')}
                ]}
            />
            {TARGET_CODE === 'PLT' ?
                <DashboardPLT
                    getGraphData={getGraphData}
                    isFetching={isFetching}
                    userData={dashboardData?.userDetails?.user}
                    notificationData={dashboardData?.lastNotification}
                    systemHealth={dashboardData?.systemHealth}
                    system={dashboardData?.gardenMap?.garden?.geoposition?.coordinates[0].map((el)=>{
                        return{
                            lat: el[0],
                            lng: el[1]
                        }
                    })}
                    areas={dashboardData?.gardenMap?.areas.map((el)=> {
                        return{
                            name: el.name,
                            paths: el.metadata?.geoposition?.coordinates[0].map((el2)=> {
                                return{
                                    lat: el2[0],
                                    lng: el2[1],
                                }
                            })
                        }
                    })}
                    plants={dashboardData?.plantsMap.map((el)=> {
                        return{
                            name: el.name,
                            geoLocation: {
                                lat: el.geoposition?.coordinates[0],
                                lng: el.geoposition?.coordinates[1]
                            }
                        }
                    })}
                    devices={dashboardData?.devicesMap.map((el)=> {
                        return{
                            name: el.name,
                            geoLocation: {
                                lat: el.metadata?.geoposition?.coordinates[0],
                                lng: el.metadata?.geoposition?.coordinates[1]
                            }
                        }
                    })}
                    areasData={areaData.map((el)=>{
                        return{
                            _id: el._id,
                            name: el.systemAreaName,
                            humChart: {
                                air: el.humChart.air.map((el2)=>{ return[moment(el2.time).valueOf(), el2.value]}),
                                soil: el.humChart.soil.map((el2)=>{ return[moment(el2.time).valueOf(), el2.value]}),
                            },
                            tempChart: {
                                air: el.tempChart.air.map((el2)=>{ return[moment(el2.time).valueOf(), el2.value]}),
                                soil: el.tempChart.soil.map((el2)=>{ return[moment(el2.time).valueOf(), el2.value]}),
                            }
                        }
                    })}
                    indexesData={dashboardData?.indexesData}
                />
                :
                <DashboardVLM
                    userData={dashboardData?.userDetails?.user}
                    notificationData={dashboardData?.lastNotification}
                    systemHealth={dashboardData?.systemHealth}
                    shipData={dashboardData?.shipMap}
                    mainDeviceData={dashboardData?.mainDeviceData}
                    tempData={dashboardData?.tempData}
                    torsionData={dashboardData?.torsionData}
                    onboardDevicesData={onboardDevicesData}
                    dashboardAlertData={dashboardAlertData}
                    isLoading={isLoading}
                />
            }
        </Layout>
    )
}

export default Dashboard;