import React, { useEffect, useRef, useState } from 'react';
import { Input, Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';

import Layout from '../../layout/layout';
import Breadcrumb from '../../components/common/Breadcrumb';
import GraphModal from '../../components/modals/GraphModal';
// DATA EXPORT MODAL IMPORT
import DataExportModal from '../../components/modals/DataExportModal';
import SearchSelect from '../../components/common/SearchSelect';

import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as History } from '../../assets/icons/history.svg';
import { config } from '../../config';

import { useDispatch, useSelector } from 'react-redux';
import {
  getDeviceList,
  getAvailableDeviceTypes,
  getDeviceHistory,
  getAllDevicesHistory,
} from '../../redux/actions/deviceActions';
import { getAvailableSystemAreas } from '../../redux/actions/systemAreaActions';
import { getAvailableDriverConfigurations } from '../../redux/actions/driverActions';
import moment from 'moment';
import request from '../../helpers/requestHelper';
import getHistoryState from '../../helpers/deviceHistoryHelper';
import getDeviceIcon from '../../helpers/deviceIconHelper';
import { devicesListTimeOut } from '../../utils/timeoutData';

import useInterval from "../../helpers/timeoutHelper"
import getGraphType from '../../helpers/deviceGraphTypeHelper';

const DevicesList = () => {
  const TARGET_CODE = config.TARGET_CODE;

  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [deviceSearch, setDeviceSearch] = useState();
  const [filterType, setFilterType] = useState();
  const [filterArea, setFilterArea] = useState();
  const [filterDriverConfig, setFilterDriverConfig] = useState();
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isExportDataOpen, setIsExportDataOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [filterData, setFilterData] = useState({
    filterDriverConfig: '',
    filterArea: '',
    filterType: '',
    deviceSearch: '',
  });

  const role = useSelector((state) => state.authData?.userDetails?.role);
  const available = useSelector((el) => el.systemData.availableList);
  const deviceData = useSelector((el) => el.deviceData);
  const areaData = useSelector((el) => el.systemAreaData);
  const driverData = useSelector((el) => el.driverData);

  useEffect(() => {
    if (
      available.length &&
      !available.some((el) => el._id === params.systemId)
    ) {
      history.push(`/systems`);
    } else {
      dispatch(
        getDeviceList(
          params.systemId,
          filterData.deviceSearch,
          filterData.filterType,
          filterData.filterArea
        ),
        filterData.filterDriverConfig
      );
      dispatch(getAvailableDeviceTypes(params.systemId));
      dispatch(getAvailableSystemAreas(params.systemId));
      dispatch(getAvailableDriverConfigurations(params.systemId));
    }
  }, []);

  useInterval(() => {
    dispatch(
      getDeviceList(
        params.systemId,
        filterData.deviceSearch,
        filterData.filterType,
        filterData.filterArea,
        filterData.filterDriverConfig
      )
    );
  }, devicesListTimeOut);

  const setFilters = () => {
    setFilterData({
      ...filterData,
      filterType: filterType,
      filterArea: filterArea,
      deviceSearch: deviceSearch,
      filterDriverConfig: filterDriverConfig,
    });
    dispatch(
      getDeviceList(
        params.systemId,
        deviceSearch,
        filterType,
        filterArea,
        filterDriverConfig
      )
    );
  };

  const onKeyHandler = (e) => {
    if (e.key === 'Enter') {
      setFilters();
    }
  };

  const onClickRow = (e, id) => {
    if (e.target.localName !== 'path' && e.target.localName !== 'svg') {
      history.push(`/system/${params.systemId}/device/view/${id}`);
    }
  };

  const openHistory = (item) => {
    dispatch(
      getDeviceHistory(item._id, params.systemId, 'lastyear', null, null)
    );
    setSelectedData(item);
    setIsHistoryOpen(true);
  };

  // GET ALL DEVICES HISTORY
  const openAllHistory = (item) => {
    //dispatch(getAllDevicesHistory(params.systemId, 'lastyear', null, null));
    // setSelectedData(item);
    setIsExportDataOpen(true);
  };

  const onExportData = async (value, from, to) => {
    let filter, start, end;
    if (value === 'custom') {
      start = from;
      end = to;
    } else {
      filter = value;
    }

    const res = await request({
      url: `driversystem/ui/devices/${selectedData._id}/export?system=${
        params.systemId
      }${filter ? '&dateFilter=' + filter : ''}${
        !filter ? '&startDate=' + start : ''
      }${!filter ? '&endDate=' + end : ''}`,
      auth: true,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    const blob = new Blob([res], {
      type: 'text.csv',
    });
    let name =
      selectedData._id + '_' + (filter ? filter : start + '_' + end) + '.csv';
    saveAs(blob, name);
  };

  //   EXPORT ALL DATA
  const onExportAllData = async (value, from, to) => {
    let filter, start, end;
    if (value === 'custom') {
      start = from;
      end = to;
    } else {
      filter = value;
    }

    const res = await request({
      url: `/driversystem/ui/devices/multiple/export?system=${params.systemId}${
        filter ? '&dateFilter=' + filter : ''
      }${!filter ? '&startDate=' + start : ''}${
        !filter ? '&endDate=' + end : ''
      }`,
      auth: true,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    const blob = new Blob([res], {
      type: 'text.csv',
    });
    let name =
      params.systemId + '_' + (filter ? filter : start + '_' + end) + '.csv';
    saveAs(blob, name);
  };

  const getGraphData = (value, from, to) => {
    if (value === 'custom') {
      dispatch(
        getDeviceHistory(selectedData._id, params.systemId, null, from, to)
      );
    } else {
      dispatch(
        getDeviceHistory(selectedData._id, params.systemId, value, null, null)
      );
    }
  };

  const getAllDevicesData = (value, from, to) => {
    if (value === 'custom') {
      dispatch(getAllDevicesHistory(params.systemId, null, from, to));
    } else {
      dispatch(getAllDevicesHistory(params.systemId, value, null, null));
    }
  };

  return (
    <Layout>
      <Breadcrumb
        title={t('devices.title')}
        systemData={{
          id: params.systemId,
          name: available.find((el) => el._id === params.systemId)?.name,
        }}
        breadcrumbItems={[
          {
            path: `system/${params.systemId}/dashboard`,
            name: t(available.find((el) => el._id === params.systemId)?.name),
          },
          {
            path: `system/${params.systemId}/devices`,
            name: t('devices.breadcrumb1'),
          },
        ]}
      />
      <Card>
        <CardBody>
          <div className="row">
            <div className="col-md-6 mt-2 mt-md-0">
              <Input
                type="text"
                id="text_search"
                placeholder={t('devices.filter.field1.placeholder')}
                value={deviceSearch}
                onChange={(e) => setDeviceSearch(e.target.value)}
                onKeyDown={onKeyHandler}
              />
            </div>

            <div className="col-md-6 mt-2 mt-md-0">
              <SearchSelect
                name="deviceType"
                placeholder={t('devices.filter.field2.placeholder')}
                data={deviceData.availableDeviceTypes.map((item) => {
                  return {
                    value: item._id,
                    label: item.name,
                  };
                })}
                value={
                  filterType
                    ? {
                        value: filterType,
                        label: deviceData.availableDeviceTypes.find(
                          (item) => item._id === filterType
                        )?.name,
                      }
                    : null
                }
                isClearable={true}
                onChangeHandler={(e) => setFilterType(e ? e.value : '')}
              />
            </div>
            {TARGET_CODE === 'PLT' && (
              <div className="col-md-6 mt-2 mt-md-3">
                <SearchSelect
                  name="area"
                  placeholder={t('devices.filter.field3.placeholder')}
                  data={areaData.availableSystemAreas.map((item) => {
                    return {
                      value: item._id,
                      label: item.name,
                    };
                  })}
                  value={
                    filterArea
                      ? {
                          value: filterArea,
                          label: areaData.availableSystemAreas.find(
                            (item) => item._id === filterArea
                          )?.name,
                        }
                      : null
                  }
                  isClearable={true}
                  onChangeHandler={(e) =>
                    setFilterArea(e ? e.value.toString() : '')
                  }
                />
              </div>
            )}
            {(role === 'admin' ||
              role === 'globalsupervisor' ||
              role === 'supervisor') && (
              <div
                className={
                  'mt-2 mt-md-3 ' +
                  (TARGET_CODE === 'PLT' ? 'col-md-6' : 'col-md-12')
                }
              >
                <SearchSelect
                  name="driverconfiguration"
                  placeholder={t('devices.filter.field4.placeholder')}
                  data={driverData.availableDriverConfigs.map((item) => {
                    return {
                      value: item._id,
                      label: item.name,
                    };
                  })}
                  value={
                    filterDriverConfig
                      ? {
                          value: filterDriverConfig,
                          label: driverData.availableDriverConfigs.find(
                            (item) => item._id === filterDriverConfig
                          )?.name,
                        }
                      : null
                  }
                  isClearable={true}
                  onChangeHandler={(e) =>
                    setFilterDriverConfig(e ? e.value.toString() : '')
                  }
                />
              </div>
            )}
            <div className="mb-3 mt-3">
              <Button onClick={setFilters} color="primary">
                {t('main.applyFilters')}
              </Button>
            </div>
            {TARGET_CODE === 'PLT' && (
              <div className="mb-3 ">
                <Button onClick={openAllHistory} color="success">
                  {t('main.exportAllDataByHour')}
                </Button>
              </div>
            )}
          </div>

          <div className="devices-table-container">
            <div>
              {deviceData.deviceList.map((item, index) => (
                <div
                  onClick={(e) => onClickRow(e, item._id)}
                  style={{ cursor: 'pointer' }}
                  className="data-row-1 row"
                  key={'devices-list-item-' + index}
                >
                  <div className="col-5">
                    <div className="pe-5">
                      {/* {item.devicetype?.toLowerCase().includes('temperature-sensor') &&
                                                <Temperature/>}
                                            {item.devicetype?.toLowerCase().includes('humidity-sensor') && <Droplet/>}
                                            {item.devicetype?.toLowerCase().includes('soil-moisture-sensor') && <Bulb
                                                className={item.defaultState === 'ON' ? 'text-new-primary-svg' : 'text-semi-dark-svg'}/>}
                                            {item.devicetype?.toLowerCase().includes('twist-sensor') && <Bolt
                                                className={item.defaultState === 'ON' ? 'text-new-primary-svg' : 'text-semi-dark-svg'}/>} */}
                      {getDeviceIcon(item.devicetype, item.state?.value)}
                    </div>
                    <div>
                      <div style={{ fontWeight: 600 }}> {item.name}</div>
                      <div>
                        {' '}
                        {
                          deviceData.availableDeviceTypes.find(
                            (el) => el._id === item.devicetype
                          )?.name
                        }
                      </div>
                      <div>
                        {' '}
                        {t('main.lastStateUpdate')}:{' '}
                        {item.state?.time
                          ? moment(item.state?.time).format('DD/MM/YYYY HH:mm')
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="col-2 text-center">{item.statelabel}</div>
                  <div className="col-5 d-flex justify-content-end">
                    {getHistoryState(item.devicetype) && (
                      <div title="button" className="me-3">
                        <div
                          style={{ width: 'max-content' }}
                          onClick={() => openHistory(item)}
                        >
                          <History style={{ cursor: 'pointer' }} />
                        </div>
                      </div>
                    )}

                    {(role === 'admin' || role.includes('supervisor')) && (
                      <div title="button">
                        <Link
                          title="button"
                          to={`/system/${params.systemId}/device/edit/${item._id}`}
                        >
                          <div style={{ width: 'max-content' }}>
                            <Edit style={{ cursor: 'pointer' }} />
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>

      <GraphModal
        isOpen={isHistoryOpen}
        setIsOpen={setIsHistoryOpen}
        title={t('devices.history.topic')}
        hideExport={TARGET_CODE === 'VLM_LOCAL'}
        getGraphData={getGraphData}
        onExport={onExportData}
        graphType={getGraphType(selectedData.devicetype)}
        graphData={{
          name: 'Current',
          data: deviceData.deviceHistory.map((el) => {
            return [moment(el.time).valueOf(), el.value];
          }),
        }}
        data={
          <div className="data-row-1 mt-3">
            <div className="col-9">
              <div className="pe-5">
                {/* {selectedData?.devicetype?.toLowerCase().includes('temperature-sensor') &&
                                    <Temperature/>}
                                {selectedData?.devicetype?.toLowerCase().includes('humidity-sensor') && <Droplet/>}
                                {selectedData?.devicetype?.toLowerCase().includes('soil-moisture-sensor') && <Bulb
                                    className={selectedData.defaultState === 'ON' ? 'text-new-primary-svg' : 'text-semi-dark-svg'}/>}
                                {selectedData?.devicetype?.toLowerCase().includes('twist-sensor') && <Bolt
                                    className={selectedData.defaultState === 'ON' ? 'text-new-primary-svg' : 'text-semi-dark-svg'}/>} */}
                {getDeviceIcon(
                  selectedData.devicetype,
                  selectedData.state?.value
                )}
              </div>
              <div>
                <div style={{ fontWeight: 600 }}> {selectedData.name}</div>
                <div>
                  {' '}
                  {
                    deviceData.availableDeviceTypes.find(
                      (el) => el._id === selectedData.devicetype
                    )?.name
                  }
                </div>
                <div>
                  {' '}
                  {t('main.lastStateUpdate')}:{' '}
                  {selectedData.state?.time
                    ? moment(selectedData.state?.time).format(
                        'DD/MM/YYYY HH:mm'
                      )
                    : '-'}
                </div>
              </div>
            </div>
            <div className="col-3 text-end">{selectedData.statelabel}</div>
          </div>
        }
      />

      {/* EXPORT ALL DATA MODAL */}
      <DataExportModal
        isOpen={isExportDataOpen}
        setIsOpen={setIsExportDataOpen}
        title={t('main.exportAllDataByHour')}
        hideExport={TARGET_CODE === 'VLM_LOCAL'}
        getAllDevicesData={getAllDevicesData}
        onExportAll={onExportAllData}
      />
    </Layout>
  );
};

export default DevicesList;
