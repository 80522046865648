export const GET_SYSTEM_PLANT_LIST_REQUEST = "GET_SYSTEM_PLANT_LIST_REQUEST";
export const GET_SYSTEM_PLANT_LIST_SUCCEED = "GET_SYSTEM_PLANT_LIST_SUCCEED";
export const GET_SYSTEM_PLANT_LIST_FAILED = "GET_SYSTEM_PLANT_LIST_FAILED";

export const GET_SYSTEM_PLANT_DATA_REQUEST = "GET_SYSTEM_PLANT_DATA_REQUEST";
export const GET_SYSTEM_PLANT_DATA_SUCCEED = "GET_SYSTEM_PLANT_DATA_SUCCEED";
export const GET_SYSTEM_PLANT_DATA_FAILED = "GET_SYSTEM_PLANT_DATA_FAILED";

export const CREATE_SYSTEM_PLANT_REQUEST = "CREATE_SYSTEM_PLANT_REQUEST";
export const CREATE_SYSTEM_PLANT_SUCCEED = "CREATE_SYSTEM_PLANT_SUCCEED";
export const CREATE_SYSTEM_PLANT_FAILED = "CREATE_SYSTEM_PLANT_FAILED";

export const UPDATE_SYSTEM_PLANT_REQUEST = "UPDATE_SYSTEM_PLANT_REQUEST";
export const UPDATE_SYSTEM_PLANT_SUCCEED = "UPDATE_SYSTEM_PLANT_SUCCEED";
export const UPDATE_SYSTEM_PLANT_FAILED = "UPDATE_SYSTEM_PLANT_FAILED";

export const DELETE_SYSTEM_PLANT_REQUEST = "DELETE_SYSTEM_PLANT_REQUEST";
export const DELETE_SYSTEM_PLANT_SUCCEED = "DELETE_SYSTEM_PLANT_SUCCEED";
export const DELETE_SYSTEM_PLANT_FAILED = "DELETE_SYSTEM_PLANT_FAILED";

export const GET_AVAILABLE_CATEGORIES_REQUEST = "GET_AVAILABLE_CATEGORIES_REQUEST";
export const GET_AVAILABLE_CATEGORIES_SUCCEED = "GET_AVAILABLE_CATEGORIES_SUCCEED";
export const GET_AVAILABLE_CATEGORIES_FAILED = "GET_AVAILABLE_CATEGORIES_FAILED";

export const GET_AVAILABLE_SPECIES_REQUEST = "GET_AVAILABLE_SPECIES_REQUEST";
export const GET_AVAILABLE_SPECIES_SUCCEED = "GET_AVAILABLE_SPECIES_SUCCEED";
export const GET_AVAILABLE_SPECIES_FAILED = "GET_AVAILABLE_SPECIES_FAILED";

export const GET_AVAILABLE_PLANTS_REQUEST = "GET_AVAILABLE_PLANTS_REQUEST";
export const GET_AVAILABLE_PLANTS_SUCCEED = "GET_AVAILABLE_PLANTS_SUCCEED";
export const GET_AVAILABLE_PLANTS_FAILED = "GET_AVAILABLE_PLANTS_FAILED";

export const GET_PLANT_SPECIES_DATA_REQUEST = "GET_PLANT_SPECIES_DATA_REQUEST";
export const GET_PLANT_SPECIES_DATA_SUCCEED = "GET_PLANT_SPECIES_DATA_SUCCEED";
export const GET_PLANT_SPECIES_DATA_FAILED = "GET_PLANT_SPECIES_DATA_FAILED";
