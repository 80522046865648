import {userActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getAvailableSupervisors = () => async dispatch => {
    dispatch({type: userActionTypes.GET_AVAILABLE_SUPERVISORS_REQUEST})

    try {
        const res = await request({
            url: `core/ui/users/availablesupervisors`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: userActionTypes.GET_AVAILABLE_SUPERVISORS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: userActionTypes.GET_AVAILABLE_SUPERVISORS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getAvailableCustomers = () => async dispatch => {
    dispatch({type: userActionTypes.GET_AVAILABLE_CUSTOMERS_REQUEST})

    try {
        const res = await request({
            url: `core/ui/users/availablecustomers`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: userActionTypes.GET_AVAILABLE_CUSTOMERS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: userActionTypes.GET_AVAILABLE_CUSTOMERS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getUserList = (page, search) => async dispatch => {
    dispatch({type: userActionTypes.GET_USER_LIST_REQUEST})

    try {
        const res = await request({
            url: `core/ui/users?page=${page}&limit=5&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: userActionTypes.GET_USER_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: userActionTypes.GET_USER_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getUserData = (id) => async dispatch => {
    dispatch({type: userActionTypes.GET_USER_DATA_REQUEST})

    try {
        const res = await request({
            url: `core/ui/users/${id}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: userActionTypes.GET_USER_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: userActionTypes.GET_USER_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addUser = (Data) => async dispatch => {
    dispatch({type: userActionTypes.CREATE_USER_REQUEST});

    let data = new URLSearchParams();

    data.append('firstname', Data.firstname);
    data.append('lastname', Data.lastname);
    data.append('email', Data.email);
    data.append('password', Data.password);
    data.append('confirmpassword', Data.confirmpassword);
    data.append('role', Data.role);

    if(Data.role === "supervisor"){
        data.append('model_modification_allowance', Data?.model_modification_allowance === 'yes');
    }

    try {
        const res = await request({
            url: `core/ui/users`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: userActionTypes.CREATE_USER_SUCCEED,
            payload: res
        });

        return true;
    } catch (err) {
        dispatch({
            type: userActionTypes.CREATE_USER_FAILED,
            payload: err ? err.data : null
        });
        return false;
    }
}

export const updateUser = (Data) => async dispatch => {
    dispatch({type: userActionTypes.UPDATE_USER_REQUEST});

    let data = new URLSearchParams();

    data.append('firstname', Data.firstname);
    data.append('lastname', Data.lastname);
    data.append('email', Data.email);
    data.append('role', Data.role);

    if(Data.role === "supervisor"){
        data.append('model_modification_allowance', Data?.model_modification_allowance === 'yes');
    }

    if(Data.password)
        data.append('password', Data.password);
    if(Data.confirmpassword)
        data.append('confirmpassword', Data.confirmpassword);

    try {
        const res = await request({
            url: `core/ui/users/${Data._id}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: userActionTypes.UPDATE_USER_SUCCEED,
            payload: res
        });
        return true;

    } catch (err) {
        dispatch({
            type: userActionTypes.UPDATE_USER_FAILED,
            payload: err ? err.data : null
        });
        return false;

    }
}

export const deleteUser = (id) => async dispatch => {
    dispatch({type: userActionTypes.DELETE_USER_REQUEST});

    try {
        const res = await request({
            url: `core/ui/users/${id}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: userActionTypes.DELETE_USER_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: userActionTypes.DELETE_USER_FAILED,
            payload: err ? err.data : null
        });
    }
}
