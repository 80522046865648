import React, {useEffect} from "react";
import {Container, Row} from "reactstrap";
import {Link, useLocation, useParams} from "react-router-dom";
import {systemData} from "../../utils/userData";
import {useTranslation} from "react-i18next";
import {config} from '../../config';
import {useSelector} from "react-redux";

const NavigationBar = ({role, logout}) => {

    const TARGET_CODE = config.TARGET_CODE;
    const location = useLocation();
    const params = useParams();
    const {t} = useTranslation();

    const availableList = useSelector((el) => el.systemData?.availableList);
    const systemId = availableList?.[0]?._id;
    const systemIrrigationEnabled = availableList.find(s => s._id === (params.systemId || systemId))?.metadata.irrigation_enabled || false;

    return (
        <Container fluid={true}>
            <Row className='navigation-container ps-3 pe-3'>
                <div className='d-md-flex flex-md-row justify-content-md-between align-items-md-center'>

                    {(role === 'admin' || role.includes('supervisor'))  &&
                        <div className='d-flex align-items-center'>
                            {role === 'admin' &&
                                <Link to={`/users`}>
                                    <div
                                        className={'me-4 d-flex ' + (location.pathname.includes(`/users`) ? 'text-primary' : '')}>
                                        <i className='bx bx-tone bx-xs me-1'/>
                                        <div>{t("navigation.users")}</div>
                                    </div>
                                </Link>
                            }

                            <Link to={`/systems`}>
                                <div
                                    className={'me-4 d-flex ' + (location.pathname.includes(`/systems`) ? 'text-primary' : '')}>
                                    <i className='bx bx-tone bx-xs me-1'/>
                                    <div>{t("navigation.systems")}</div>
                                </div>
                            </Link>

                            {(role === 'admin' || role.includes('supervisor')) &&
                                <>
                                    {TARGET_CODE === 'PLT' &&
                                        <Link
                                            to={`/plant-categories`}>
                                            <div
                                                className={'d-flex ' + (location.pathname.includes(`/plant-categories`) ? 'text-primary' : '')}>
                                                <i className='bx bx-tone bx-xs me-1'/>
                                                <div>{t("navigation.plantCategories")}</div>
                                            </div>
                                        </Link>
                                    }
                                </>
                            }
                        </div>
                    }

                    <div className='d-flex'>
                        <Link
                            to={`/system/${params.systemId ? params.systemId : systemId}/dashboard`}>
                            <div
                                className={'me-4 d-flex ' + (location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/dashboard`) ? 'text-primary' : '')}>
                                <i className='bx bx-tone bx-xs me-1'/>
                                <div>{t("navigation.dashboard")}</div>
                            </div>
                        </Link>

                        {(role === 'admin' || role.includes('supervisor')) &&
                            <>
                                {TARGET_CODE === 'PLT' &&
                                    <Link
                                        to={`/system/${params.systemId ? params.systemId : systemId}/areas`}>
                                        <div
                                            className={'me-4 d-flex ' + (location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/area`) ? 'text-primary' : '')}>
                                            <i className='bx bx-tone bx-xs me-1'/>
                                            <div>{t("navigation.areas")}</div>
                                        </div>
                                    </Link>
                                }
                            </>
                        }

                        <Link
                            to={`/system/${params.systemId ? params.systemId : systemId}/devices`}>
                            <div
                                className={'me-4 d-flex ' + (location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/device`) ? 'text-primary' : '')}>
                                <i className='bx bx-tone bx-xs me-1'/>
                                <div>{t("navigation.devices")}</div>
                            </div>
                        </Link>

                        {((role === 'admin' || role.includes('supervisor')) && systemIrrigationEnabled) &&
                            <>
                                {TARGET_CODE === 'PLT' &&
                                    <Link
                                        to={`/system/${params.systemId ? params.systemId : systemId}/irrigation-management`}>
                                        <div
                                            className={'me-4 d-flex ' + (location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/irrigation-management`) ? 'text-primary' : '')}>
                                            <i className='bx bx-tone bx-xs me-1'/>
                                            <div>{t("navigation.irrigationManagement")}</div>
                                        </div>
                                    </Link>
                                }
                            </>
                        }

                        {TARGET_CODE !== "VLM_LOCAL" &&
                            <>
                                {TARGET_CODE === 'PLT' &&
                                    <Link
                                        to={`/system/${params.systemId ? params.systemId : systemId}/rule-entities`}>
                                        <div
                                            className={'me-4 d-flex ' + (location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/rule-entities`) ? 'text-primary' : '')}>
                                            <i className='bx bx-tone bx-xs me-1'/>
                                            <div>{t("navigation.ruleEntities")}</div>
                                        </div>
                                    </Link>
                                }

                                {role === 'admin' &&
                                    <Link to={`/system/${params.systemId ? params.systemId : systemId}/drivers`}>
                                        <div
                                            className={'me-4 d-flex ' + (location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/driver`) ? 'text-primary' : '')}>
                                            <i className='bx bx-tone bx-xs me-1'/>
                                            <div>{t("navigation.drivers")}</div>
                                        </div>
                                    </Link>
                                }

                                {(role === 'admin' || role.includes('supervisor'))  &&
                                    <Link to={`/system/${params.systemId ? params.systemId : systemId}/rules`}>
                                        <div
                                            className={'me-4 d-flex ' + ((location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/rule`) && (!location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/rule-entities`))) ? 'text-primary' : '')}>
                                            <i className='bx bx-tone bx-xs me-1'/>
                                            <div>{t("navigation.rules")}</div>
                                        </div>
                                    </Link>
                                }

                                {TARGET_CODE === 'PLT' &&
                                    <Link
                                        to={`/system/${params.systemId ? params.systemId : systemId}/plants`}>
                                        <div
                                            className={'me-4 d-flex ' + (location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/plant`) ? 'text-primary' : '')}>
                                            <i className='bx bx-tone bx-xs me-1'/>
                                            <div>{t("navigation.plants")}</div>
                                        </div>
                                    </Link>
                                }

                                {(role === 'admin' || role.includes('supervisor')) &&
                                    <>
                                        {TARGET_CODE === 'PLT' &&
                                            <Link
                                                to={`/system/${params.systemId ? params.systemId : systemId}/register-entries`}>
                                                <div
                                                    className={'me-4 d-flex ' + (location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/register-entries`) ? 'text-primary' : '')}>
                                                    <i className='bx bx-tone bx-xs me-1'/>
                                                    <div>{t("navigation.registerEntries")}</div>
                                                </div>
                                            </Link>
                                        }
                                    </>
                                }

                                {TARGET_CODE === 'PLT' &&
                                    <Link
                                        to={`/system/${params.systemId ? params.systemId : systemId}/garden-info`}>
                                        <div
                                            className={'d-flex ' + (location.pathname.includes(`/system/${params.systemId ? params.systemId : systemId}/garden-info`) ? 'text-primary' : '')}>
                                            <i className='bx bx-tone bx-xs me-1'/>
                                            <div>{t("navigation.gardenInfo")}</div>
                                        </div>
                                    </Link>
                                }
                            </>
                        }
                    </div>

                    <div className='d-flex'>
                        <div>
                            <div onClick={logout} className='d-flex' style={{cursor: 'pointer'}}>
                                <i className='bx bx-tone bx-xs me-1'/>
                                <div>{t("navigation.logout")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    )
}

export default NavigationBar