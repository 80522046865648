import React, {useEffect, useState} from "react";
import Layout from "../../layout/layout";

import Breadcrumb from "../../components/common/Breadcrumb";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    getDashboardDataVLM,
} from "../../redux/actions/dashboardActions";
import GardenInfoCard1 from "./GardenInfoCard1";
import GardenInfoCard2 from "./GardenInfoCard2";
import GardenInfoCard3 from "./GardenInfoCard3";
import GardenInfoCard4 from "./GardenInfoCard4";
import GardenInfoCard5 from "./GardenInfoCard5";
import GardenInfoCard6 from "./GardenInfoCard6";
import GardenInfoCard7 from "./GardenInfoCard7";
import {getGardenInfoSectionData, getGardenTypesOptions} from "../../redux/actions/gardenInfoActions";

const GardenInfo=()=>{

    const params = useParams();
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const available = useSelector((el) => el.systemData.availableList)
    const isFetching = useSelector((el)=> el.dashboardData.isFetching)
    const errorData = useSelector((el) => el.loadingData.error)
    const role = useSelector((state) => state.authData?.userDetails?.role)

    const [isValidated, setIsValidated] = useState(false)

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            if(role === 'customer' || role === 'guest'){
                history.push(`/system/${available[0]._id}/dashboard`)
            } else {
                history.push(`/systems`)
            }
        } else {
            setIsValidated(true)
            dispatch(getGardenInfoSectionData(params.systemId, 1))
            dispatch(getGardenInfoSectionData(params.systemId, 2))
            dispatch(getGardenInfoSectionData(params.systemId, 4))
            dispatch(getGardenInfoSectionData(params.systemId, 5))
            dispatch(getGardenInfoSectionData(params.systemId, 6))
            dispatch(getGardenInfoSectionData(params.systemId, 7))
            dispatch(getGardenTypesOptions())
        }
    }, [location.pathname])

    useEffect(() => {
        if (errorData && errorData.code === 404 && !isFetching) {
            if(role === 'customer' || role === 'guest'){
                history.push(`/system/${available[0]._id}/dashboard`)
            } else {
                history.push(`/systems`)
            }
        }
    }, [errorData])

    return(
        <Layout>
            <Breadcrumb
                title={t('gardenInfo.title')}
                systemData={{id: params.systemId, name: available.find((el) => el._id === params.systemId)?.name}}
                breadcrumbItems={[
                    {path: `system/${params.systemId}/garden-info`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                    {path: `system/${params.systemId}/garden-info`, name: t('gardenInfo.breadcrumb')}
                ]}
            />

            {isValidated ?
                <div>
                    <GardenInfoCard1 isDisabled={role === 'customer' || role === 'guest'} />

                    <GardenInfoCard2 isDisabled={role === 'customer' || role === 'guest'}/>

                    {!(role === 'customer' || role === 'guest') ?
                        <GardenInfoCard3/>
                    :""}

                    <GardenInfoCard4 isDisabled={role === 'customer' || role === 'guest'}/>

                    <GardenInfoCard5 isDisabled={role === 'customer' || role === 'guest'}/>

                    <GardenInfoCard6 isDisabled={role === 'customer' || role === 'guest'}/>

                    <GardenInfoCard7 isDisabled={role === 'customer' || role === 'guest'}/>
                </div>
            :""}
        </Layout>
    )
}

export default GardenInfo;