import {systemAreaActionTypes, systemPlantActionTypes} from "../actionTypes/index";
import produce from "immer";

const initialState = {
    availableCategories:[],
    availableSpecies:[],
    availablePlants:[],
    systemPlantList: [],
    systemPlantData: null,
    plantSpeciesData: null,
    totalSize: 0
};

const systemPlantData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case systemPlantActionTypes.GET_AVAILABLE_CATEGORIES_SUCCEED: {
                state.availableCategories = payload;
                return state;
            }
            case systemPlantActionTypes.GET_AVAILABLE_SPECIES_SUCCEED: {
                state.availableSpecies = payload;
                return state;
            }
            case systemPlantActionTypes.GET_AVAILABLE_PLANTS_SUCCEED: {
                state.availablePlants = payload;
                return state;
            }
            case systemPlantActionTypes.GET_SYSTEM_PLANT_LIST_SUCCEED: {
                state.systemPlantList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case systemPlantActionTypes.GET_SYSTEM_PLANT_DATA_SUCCEED: {
                state.systemPlantData = payload;
                return state;
            }
            case systemPlantActionTypes.CREATE_SYSTEM_PLANT_SUCCEED: {
                state.systemPlantList.push(payload);
                state.totalSize++;
                return state;
            }
            case systemPlantActionTypes.UPDATE_SYSTEM_PLANT_SUCCEED: {
                const updatedId = state.systemPlantList.findIndex((item)=>item._id===payload._id);
                state.systemPlantList[updatedId]=payload;
                return state;
            }
            case systemPlantActionTypes.DELETE_SYSTEM_PLANT_SUCCEED: {
                const deletedId = state.systemPlantList.findIndex((item)=>item._id===payload._id);
                state.systemPlantList.splice(deletedId,1);
                state.totalSize--;
                return state;
            }
            case systemPlantActionTypes.GET_PLANT_SPECIES_DATA_REQUEST: {
                state.plantSpeciesData = null;
                return state;
            }
            case systemPlantActionTypes.GET_PLANT_SPECIES_DATA_SUCCEED: {
                state.plantSpeciesData = payload;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default systemPlantData;
