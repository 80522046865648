import React from 'react';
import { Spinner } from 'reactstrap';


const OverlaySpinner=(props)=>{

	const styles = {
		full:{
			backgroundColor:'rgba(255,255,255,0.98)', 
			width:'100%', 
			height:'100%', 
			position:'fixed', 
			zIndex:'1000',
			marginLeft: '-35px'
		},
		table:{
			backgroundColor:'rgba(255,255,255,0.7)', 
			width:'100%', 
			height:'100%', 
			position:'absolute', 
			zIndex:'1000',
			marginLeft: '-17px',
			marginTop: props.marginTop ? props.marginTop : '-45px',
		},
		fullSpinner:{
			position:'fixed', 
			top:'45%', 
			left:'50%',
			marginLeft:'-17px',
			zIndex:'1000'
		},
		tableSpinner:{
			position:'fixed', 
			top: props.top ? props.top : '45%',
			left:'48%',
			zIndex:'1000'
		}
	}

	if(props.isLoading){
		return(
			<div style={props.tableLoader ? styles.table : styles.full}>
				<div style={props.tableLoader ? styles.tableSpinner : styles.fullSpinner}>
					<Spinner style={{color:"#556ee6"}}  animation='border'>	
					{' '}						
					</Spinner>	
		        </div>	
	        </div>	
		)
	}else{
		return null
	}
}

export default OverlaySpinner;