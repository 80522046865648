import React, {useEffect, useState} from "react";
import {Card, CardBody, Input} from "reactstrap";
import { useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {Button, Form, Row} from "react-bootstrap";
import {updateGardenInfoSectionData} from "../../redux/actions/gardenInfoActions";

const GardenInfoCard5=({isDisabled})=>{

    const params = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const sectionData = useSelector((el)=> el.gardenInfoData.gardenInfoData?.section5)

    const initialFormValue = {
        phisical_characteristics: "",
        ph: "",
        salinity: "",
        chemical_analysis: "",
        notes: ""
    }

    const [formState, setFormState] = useState(initialFormValue)

    useEffect(() => {
        if(sectionData && sectionData?.soil_characteristics) {
            setFormState({
                ...initialFormValue,
                ...sectionData?.soil_characteristics
            })
        }
    }, [JSON.stringify(sectionData)]);

    const onSaveClick = (e) => {
        e.preventDefault();

        dispatch(updateGardenInfoSectionData(formState, params.systemId, 5))
    }

    const onChangeHandler= (e) => {
        const {name, value} = e.target;
        if((name === "notes" || name === "chemical_analysis" || name === "phisical_characteristics")) {
            if(value.length <= 500) {
                setFormState({
                    ...formState,
                    [name]: value
                })
            }
        } else {
            setFormState({
                ...formState,
                [name]: value
            })
        }
    }

    return(
        <Card>
            <CardBody>
                <div>
                    <div className='font-size-15 fw-medium'>{t("gardenInfo.card5.topic")}</div>
                </div>
                <form onSubmit={onSaveClick}>
                    <Row>
                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='phisical_characteristics'>{t('gardenInfo.card5.form1.name')}</label>
                            </div>
                            <div className='form-limitation-container'>
                                <Input
                                    type='textarea'
                                    name='phisical_characteristics'
                                    id='phisical_characteristics'
                                    className='form-control'
                                    value={formState.phisical_characteristics}
                                    onChange={onChangeHandler}
                                    placeholder={t('gardenInfo.card5.form1.placeholder')}
                                    disabled={isDisabled}
                                />
                                <div>{formState.phisical_characteristics.length}/500</div>
                            </div>
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='ph'>{t('gardenInfo.card5.form2.name')}</label>
                            </div>
                            <Input
                                type='text'
                                name='ph'
                                id='ph'
                                className='form-control'
                                value={formState.ph}
                                onChange={onChangeHandler}
                                disabled={isDisabled}
                            />
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='salinity'>{t('gardenInfo.card5.form3.name')}</label>
                            </div>
                            <Input
                                type='text'
                                name='salinity'
                                id='salinity'
                                className='form-control'
                                value={formState.salinity}
                                onChange={onChangeHandler}
                                disabled={isDisabled}
                            />
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='chemical_analysis'>{t('gardenInfo.card5.form4.name')}</label></div>
                            <div className='form-limitation-container'>
                                <Input
                                    type='textarea'
                                    name='chemical_analysis'
                                    id='chemical_analysis'
                                    className='form-control'
                                    value={formState.chemical_analysis}
                                    onChange={onChangeHandler}
                                    placeholder={t('gardenInfo.card5.form4.placeholder')}
                                    disabled={isDisabled}
                                />
                                <div>{formState.chemical_analysis.length}/500</div>
                            </div>
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='notes'>{t('gardenInfo.card5.form5.name')}</label></div>
                            <div className='form-limitation-container'>
                                <Input
                                    type='textarea'
                                    name='notes'
                                    id='notes'
                                    className='form-control'
                                    value={formState.notes}
                                    onChange={onChangeHandler}
                                    placeholder={t('gardenInfo.card5.form5.placeholder')}
                                    disabled={isDisabled}
                                />
                                <div>{formState.notes.length}/500</div>
                            </div>
                        </div>

                    </Row>

                    {!isDisabled ?
                        <div className='col-12 text-end mt-3 form-btn-grp'>
                            <Button color='primary' type='submit' className='me-2'>{t('main.save')}</Button>
                        </div>
                    :""}

                </form>
            </CardBody>
        </Card>
    )
}

export default GardenInfoCard5;