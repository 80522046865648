import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Input} from "reactstrap";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {Button, Form, Row} from "react-bootstrap";
import {roleData} from "../../utils/appData";
import Map_with_Polygon from "../../components/common/Map_with_Polygon";
import {updateGardenInfoSectionData} from "../../redux/actions/gardenInfoActions";
import Map from "../../components/common/Map";

const GardenInfoCard2=({isDisabled})=>{

    const params = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const sectionData = useSelector((el)=> el.gardenInfoData.gardenInfoData?.section2)

    const initialFormValue = {
        address: "",
        mapCenter: null,
        paths: []
    }

    const [formState, setFormState] = useState(initialFormValue)

    useEffect(() => {
        if(sectionData && sectionData?.localization) {
            const polygonPaths = sectionData?.coordinates?.length ? sectionData?.coordinates[0] : []

            let lng = 0;
            let lat = 0;
            for(let i=0; i<polygonPaths.length; i++){
                lat += Number(polygonPaths[i][0]);
                lng += Number(polygonPaths[i][1]);
            }

            const mapCenter =  {
                lat: lat/polygonPaths.length,
                lng: lng/polygonPaths.length
            }

            setFormState({
                ...initialFormValue,
                ...sectionData?.localization,
                paths: polygonPaths.map((el)=>{
                        return{
                            lat: Number(el[0]),
                            lng: Number(el[1])
                        }
                    }),
                mapCenter: polygonPaths.length > 0 ? mapCenter : null
            })
        }
    }, [JSON.stringify(sectionData)]);

    const onSaveClick = (e) => {
        e.preventDefault();

        dispatch(updateGardenInfoSectionData(formState, params.systemId, 2))
    }

    const onChangeHandler= (e) => {
        const {name, value} = e.target;
        setFormState({
            ...formState,
            [name]: value
        })
    }

    return(
        <Card>
            <CardBody>
                <div>
                    <div className='font-size-15 fw-medium'>{t("gardenInfo.card2.topic")}</div>
                </div>
                <form onSubmit={onSaveClick}>
                    <Row>

                        <Col md={6} className="mt-3">
                            <Map
                                systemPolygon={null}
                                strokeColor={'#ECB753'}
                                polygonPaths={formState.paths}
                                center={formState.mapCenter}
                                isView={true}
                                isMarkerDisabled={true}
                            />
                        </Col>
                        <Col md={6}>
                            {formState.paths?.map((el, index) => {
                                return (
                                    <div className="d-flex mt-2">
                                        <Input
                                            type='number'
                                            name='lat'
                                            id={'lat-' + index}
                                            className='form-control me-2'
                                            value={el.lat}
                                            disabled
                                            style={{maxWidth: "200px"}}
                                        />
                                        <Input
                                            type='number'
                                            name='lng'
                                            id={'lng-' + index}
                                            className='form-control'
                                            value={el.lng}
                                            disabled
                                            style={{maxWidth: "200px"}}
                                        />
                                    </div>
                                )
                            })}
                        </Col>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='address'>{t('gardenInfo.card2.form1.name')}</label></div>
                            <Input
                                type='text'
                                name='address'
                                id='address'
                                className='form-control'
                                value={formState.address}
                                onChange={onChangeHandler}
                                disabled={isDisabled}
                            />
                        </div>

                    </Row>

                    {!isDisabled ?
                        <div className='col-12 text-end mt-3 form-btn-grp'>
                            <Button color='primary' type='submit' className='me-2'>{t('main.save')}</Button>
                        </div>
                    :""}

                </form>
            </CardBody>
        </Card>
    )
}

export default GardenInfoCard2;