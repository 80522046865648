import React, {useEffect, useState} from "react";
import {Button, Form, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";



const PlantForm = (props) => {

    const {t} = useTranslation();
    const params = useParams();

    const onChangeHandler = (e) => {
        const {name, value} = e.target;

        if (value.length <= 50 || (name === 'notes' && value.length <= 500)) {
            let dataObj = {
                ...props.formState,
                [name]: value
            }

            if(name === "category") {
                dataObj.species = ""
            }

            props.setFormState(dataObj)
        }

    }

    return (

        <form onSubmit={props.onSaveClick}>
            <Row>
                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='name'>{t('plants.form.field1.name')} *</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type='text'
                            name='name'
                            id='name'
                            className='form-control'
                            value={props.formState.name}
                            onChange={onChangeHandler}
                            required
                            placeholder={t('plants.form.field1.placeholder')}
                        />
                        <div>{props.formState.name.length}/50</div>
                    </div>
                </div>


                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='systemArea'>{t('plants.form.field4.name')} *</label></div>
                    <div>
                        <Input
                            type='select'
                            name='systemArea'
                            id='systemArea'
                            className='form-select'
                            value={props.formState.systemArea}
                            onChange={onChangeHandler}
                            required
                        >
                            <option hidden value=''>{t('plants.form.field4.placeholder')}</option>
                            {props.systemareas.map((el, id) => {
                                return (
                                    <option key={"systemArea-" + id} value={el._id}>{t(el.name)}</option>
                                )
                            })}
                        </Input>
                    </div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='category'>{t('plants.form.field2.name')} *</label></div>
                    <div>
                        <Input
                            type='select'
                            name='category'
                            id='category'
                            className='form-select'
                            value={props.formState.category}
                            onChange={onChangeHandler}
                            required
                        >
                            <option hidden value=''>{t('plants.form.field2.placeholder')}</option>
                            {props.categories.map((el, id) => {
                                return (
                                    <option key={"category-" + id} value={el._id}>{t(el.name)}</option>
                                )
                            })}
                        </Input>
                    </div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='species'>{t('plants.form.field3.name')} *</label></div>
                    <div>
                        <Input
                            type='select'
                            name='species'
                            id='species'
                            className='form-select'
                            value={props.formState.species}
                            onChange={onChangeHandler}
                            required
                        >
                            <option hidden value=''>{t('plants.form.field3.placeholder')}</option>
                            {props.species.filter((el) => el.category_id === props.formState.category)?.map((el, id) => {
                                return (
                                    <option key={"species-" + id} value={el._id}>{el.name}</option>
                                )
                            })}
                        </Input>
                    </div>
                </div>

                {(props.formState.species && props.isEdit) ?
                    <div className='view-entity-container'>
                        <div className="mt-5 col-12">
                            <hr/>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field1")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.scientific_name}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field2")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.min_vegetation_temp}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field3")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.max_vegetation_temp}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field4")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.ec_max}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field5")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.ph_min}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field6")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.ph_max}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field7")}</div>
                            <div>
                                <div className="d-flex">
                                    <Form.Check
                                        type="radio"
                                        value="yes"
                                        id="resistance_to_frost-yes"
                                        name="resistance_to_frost"
                                        label={t('plants.form.species.field7.value1')}
                                        checked={props?.plantSpeciesData?.resistance_to_frost === true}
                                        className="me-4"
                                        readOnly
                                    />
                                    <Form.Check
                                        type="radio"
                                        value="no"
                                        id="resistance_to_frost-no"
                                        name="resistance_to_frost"
                                        label={t('plants.form.species.field7.value2')}
                                        checked={props?.plantSpeciesData?.resistance_to_frost === false}
                                        className="me-4"
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field8")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.co2}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field9")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.pm10}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field10")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.pm2_5}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field11")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.no2}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field12")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.so2}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className="mb-2">{t("plants.form.species.field13")}</div>
                            <div>
                                <div className='form-control'>{props?.plantSpeciesData?.o3}</div>
                            </div>
                        </div>

                        <div className="mt-5 col-12">
                            <hr/>
                        </div>
                    </div>
                    : ""}

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='notes'>{t('plants.form.notes.name')}</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type='textarea'
                            name='notes'
                            id='notes'
                            className='form-control'
                            value={props.formState.notes}
                            onChange={onChangeHandler}
                            rows={3}
                            placeholder={t('plants.form.notes.placeholder')}
                        />
                        <div>{props.formState.notes.length}/500</div>
                    </div>
                </div>
            </Row>

            <div className='col-12 text-end mt-3 form-btn-grp'>
                <Button color='primary' type='submit' className='me-2'>{t('main.save')}</Button>
                <Link to={`/system/${params.systemId}/plants`}><Button type='button'
                                                                       className='btn-secondary'>{t('main.cancel')}</Button></Link>
            </div>

        </form>
    )
}


export default PlantForm;