import { deviceActionTypes } from '../actionTypes/index';

import request from '../../helpers/requestHelper';

export const getDeviceList =
  (id, searchText, devicetype, systemarea, driverconfiguration) =>
  async (dispatch) => {
    dispatch({ type: deviceActionTypes.GET_DEVICE_LIST_REQUEST });

    try {
      const res = await request({
        url: `driversystem/ui/devices?system=${id}&limit=200${
          searchText ? '&searchText=' + searchText : ''
        }${devicetype ? '&devicetype=' + devicetype : ''}${
          systemarea ? '&systemarea=' + systemarea : ''
        }${
          driverconfiguration
            ? '&driverconfiguration=' + driverconfiguration
            : ''
        }`,
        auth: true,
        method: 'GET',
      });
      dispatch({
        type: deviceActionTypes.GET_DEVICE_LIST_SUCCEED,
        payload: res,
      });
    } catch (err) {
      dispatch({
        type: deviceActionTypes.GET_DEVICE_LIST_FAILED,
        payload: err ? err.data : null,
      });
    }
  };

export const getAvailableDeviceTypes = (id) => async (dispatch) => {
  dispatch({ type: deviceActionTypes.GET_AVAILABLE_DEVICE_TYPES_REQUEST });

  try {
    const res = await request({
      url: `driversystem/ui/devices/availabledevicetypes?system=${id}`,
      auth: true,
      method: 'GET',
    });
    dispatch({
      type: deviceActionTypes.GET_AVAILABLE_DEVICE_TYPES_SUCCEED,
      payload: res,
    });
  } catch (err) {
    dispatch({
      type: deviceActionTypes.GET_AVAILABLE_DEVICE_TYPES_FAILED,
      payload: err ? err.data : null,
    });
  }
};

export const getDeviceData = (id, systemId) => async (dispatch) => {
  dispatch({ type: deviceActionTypes.GET_DEVICE_DATA_REQUEST });

  try {
    const res = await request({
      url: `driversystem/ui/devices/${id}?system=${systemId}`,
      auth: true,
      method: 'GET',
    });
    dispatch({
      type: deviceActionTypes.GET_DEVICE_DATA_SUCCEED,
      payload: res,
    });
  } catch (err) {
    dispatch({
      type: deviceActionTypes.GET_DEVICE_DATA_FAILED,
      payload: err ? err.data : null,
    });
  }
};

export const getDeviceHistory =
  (id, systemId, dateFilter, startDate, endDate) => async (dispatch) => {
    dispatch({ type: deviceActionTypes.GET_DEVICE_HISTORY_REQUEST });

    try {
      const res = await request({
        url: `driversystem/ui/devices/${id}/history?system=${systemId}${
          dateFilter ? '&dateFilter=' + dateFilter : ''
        }${!dateFilter ? '&startDate=' + startDate : ''}${
          !dateFilter ? '&endDate=' + endDate : ''
        }`,
        auth: true,
        method: 'GET',
      });
      dispatch({
        type: deviceActionTypes.GET_DEVICE_HISTORY_SUCCEED,
        payload: res,
      });
    } catch (err) {
      dispatch({
        type: deviceActionTypes.GET_DEVICE_HISTORY_FAILED,
        payload: err ? err.data : null,
      });
    }
  };

//   GET ALL DEVICES HISTORY
export const getAllDevicesHistory =
  (systemId, dateFilter, startDate, endDate) => async (dispatch) => {
    dispatch({ type: deviceActionTypes.GET_ALL_DEVICES_HISTORY_REQUEST });

    try {
      const res = await request({
        url: `/driversystem/ui/devices/multiple/export?system=${systemId}${
          dateFilter ? '&dateFilter=' + dateFilter : ''
        }${!dateFilter ? '&startDate=' + startDate : ''}${
          !dateFilter ? '&endDate=' + endDate : ''
        }`,
        auth: true,
        method: 'GET',
      });
      dispatch({
        type: deviceActionTypes.GET_ALL_DEVICES_HISTORY_SUCCEED,
        payload: res,
      });
    } catch (err) {
      dispatch({
        type: deviceActionTypes.GET_ALL_DEVICES_HISTORY_FAILED,
        payload: err ? err.data : null,
      });
    }
  };

export const updateDevice = (Data, vlm, systemId) => async (dispatch) => {
  dispatch({ type: deviceActionTypes.UPDATE_DEVICE_REQUEST });

  // let data = new FormData();
  let data = new URLSearchParams();

  if(vlm) {
      data.append('topViewTop', Data.topViewTop);
      data.append('topViewLeft', Data.topViewLeft);
      data.append('sideViewTop', Data.sideViewTop);
      data.append('sideViewLeft', Data.sideViewLeft);
  } else {
      // data.append('systemarea', Data.systemarea);
      data.append('systemareas', JSON.stringify(Data.systemareas));

      if(Data.geoposition) {
          data.append('geoposition', Data.geoposition ? JSON.stringify(Data.geoposition) : null);
      }
  }

  try {
    const res = await request({
      url: `driversystem/ui/devices/${Data._id}/info?system=${systemId}`,
      auth: true,
      method: 'PATCH',
      data: data,
    });

    res.id = Data.id;

    dispatch({
      type: deviceActionTypes.UPDATE_DEVICE_SUCCEED,
      payload: res,
    });
    return true;
  } catch (err) {
    dispatch({
      type: deviceActionTypes.UPDATE_DEVICE_FAILED,
      payload: err ? err.data : null,
    });

    return false;
  }
};
