import React from 'react'
import { Modal } from 'reactstrap'
import ImageGallery from "react-image-gallery";
import OverlaySpinner from "../common/OverlaySpinner";

const ImagePreviewModal=({isOpen, setIsOpen, imagesData, startIndex, isLoading})=>{

    return(
        <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            tabIndex="-1"
            data-toggle="modal"
            toggle={setIsOpen}
            size="lg"
            className="image-preview-modal"
        >
            <div className="modal-content">
                <div className="modal-body" style={{maxHeight: "80vh", minHeight: 100}}>
                    {isLoading ?
                        <OverlaySpinner marginTop={'-14px'} top={"47%"} isLoading={true} tableLoader={true} />
                        :
                        <ImageGallery
                            startIndex={startIndex}
                            autoPlay={false}
                            showPlayButton={false}
                            showBullets={false}
                            showFullscreenButton={false}
                            showThumbnails={false}
                            items={imagesData}
                        />
                    }
                </div>
            </div>
        </Modal>
    )
}

export default ImagePreviewModal;
