import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, Input} from "reactstrap";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, Row} from "react-bootstrap";

import DraggableImageViewer from "../../components/common/DraggableImageViewer";

import {updateGardenInfoSectionData} from "../../redux/actions/gardenInfoActions";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const GardenInfoCard7=({isDisabled})=>{

    const params = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const imgPickerRef = useRef(null);

    const sectionData = useSelector((el)=> el.gardenInfoData.gardenInfoData?.section7)

    const [fileState, setFileState] = useState([])

    useEffect(() => {
        if(sectionData && sectionData?.length >= 0) {
            let imgArr = [];
            for(let i=0; i<sectionData?.length; i++) {
                const id = sectionData[i]?._id;
                const fileObj = {
                    _id: id,
                    url: `${baseURL}core/ui/gardenInfo/images/${id}?system=${params.systemId}&size=small`
                }
                imgArr.push(fileObj);
            }
            setFileState(imgArr);
        }
    }, [JSON.stringify(sectionData)]);

    const onChangeHandlerFile = (e) => {
        let imgArr = [];

        for(let i=0; i<fileState.length; i++) {
            imgArr.push(fileState[i])
        }


        for(let i=0; i<e.target.files.length; i++) {
            if(imgArr.length < 5) {
                imgArr.push({_id: "", file:e.target.files[i]})
            }
        }
        setFileState(imgArr);
        e.target.value = null;
    }

    const onSaveClick = (e) => {
        e.preventDefault();

        dispatch(updateGardenInfoSectionData(fileState, params.systemId, 7))
    }

    return(
        <Card>
            <CardBody>
                <div>
                    <div className='font-size-15 fw-medium'>{t("gardenInfo.card7.topic")}</div>
                    {!isDisabled ?<div className='text-semi-dark mt-2 mb-4'>{t("gardenInfo.card7.subTopic")}</div> : ""}
                </div>
                <form onSubmit={onSaveClick}>
                    <div>
                        {!isDisabled ?
                            <Button disabled={fileState.length >= 5} color='primary' type="button" onClick={() => imgPickerRef.current.click()} className='me-2 mt-2 mb-3'>{t('main.chooseFiles')}</Button>
                        :""}

                        <Input
                            innerRef={imgPickerRef}
                            type='file'
                            name='info-image'
                            id='info-image'
                            className='form-control'
                            onChange={onChangeHandlerFile}
                            accept=".jpg, .jpeg, .png"
                            multiple
                            onClick={(event)=> {
                                event.target.value = null
                            }}
                            style={{display: "none"}}
                            disabled={isDisabled}
                        />

                        <DraggableImageViewer isDisabled={isDisabled} imageData={fileState} setImageData={setFileState} />
                    </div>

                    {!isDisabled ?
                        <div className='col-12 text-end mt-3 form-btn-grp'>
                            <Button color='primary' disabled={fileState.length > 5} type='submit' className='me-2'>{t('main.save')}</Button>
                        </div>
                    :""}

                </form>
            </CardBody>
        </Card>
    )
}

export default GardenInfoCard7;