export const GET_GARDEN_TYPES_REQUEST = "GET_GARDEN_TYPES_REQUEST";
export const GET_GARDEN_TYPES_SUCCEED = "GET_GARDEN_TYPES_SUCCEED";
export const GET_GARDEN_TYPES_FAILED = "GET_GARDEN_TYPES_FAILED";

export const GET_GARDEN_INFO_SECTION_DATA_REQUEST = "GET_GARDEN_INFO_SECTION_DATA_REQUEST";
export const GET_GARDEN_INFO_SECTION_DATA_SUCCEED = "GET_GARDEN_INFO_SECTION_DATA_SUCCEED";
export const GET_GARDEN_INFO_SECTION_DATA_FAILED = "GET_GARDEN_INFO_SECTION_DATA_FAILED";

export const UPDATE_GARDEN_INFO_SECTION_REQUEST = "UPDATE_GARDEN_INFO_SECTION_REQUEST";
export const UPDATE_GARDEN_INFO_SECTION_SUCCEED = "UPDATE_GARDEN_INFO_SECTION_SUCCEED";
export const UPDATE_GARDEN_INFO_SECTION_FAILED = "UPDATE_GARDEN_INFO_SECTION_FAILED";